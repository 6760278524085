import React from "react";

import map from "lodash/map";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import { QUERY_COMPANY_NAMEBOARD } from "../../../../config/graphql/query";

const BoardRoute = React.memo(() => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation(["company", "common"]);

  const { data } = useQuery(QUERY_COMPANY_NAMEBOARD, {
    skip: !id,
    variables: {
      id,
      filter: { property: id },
    },
  });

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">{t("company:boards.th.screen")}</th>
            <th scope="col">{t("company:boards.th.entity")}</th>
            <th scope="col">{t("company:boards.th.type")}</th>
            <th scope="col">{t("company:boards.th.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.company?.nameboards?.map(({ id, title, items }: INameboard) =>
            items.map((item) => {
              const { entity } = item;

              const entityTitle = [
                ...map(entity?.parents, "title").reverse(),
                title,
              ]
                .filter(Boolean)
                .join(" - ");

              const to = [
                "/entity",
                map(entity?.parents, "id").reverse().join("/"),
                `${entity.id}/view`,
              ]
                .filter(Boolean)
                .join("/");

              return (
                <tr key={item.id}>
                  <td>{title}</td>
                  <td>{entityTitle}</td>
                  <td>{entity.type}</td>
                  <td>
                    <Link to={`/screen/${id}`} className="mr-2">
                      <Button size="sm" variant="primary">
                        {t("company:boards.links.viewScreen")}
                      </Button>
                    </Link>
                    <Link to={`/screen/${id}/item/${item.id}`} className="mr-2">
                      <Button size="sm" variant="primary">
                        {t("company:boards.links.viewScreenItem")}
                      </Button>
                    </Link>
                    <Link to={to} className="mr-2">
                      <Button size="sm" variant="primary">
                        {t("company:boards.links.viewEntity")}
                      </Button>
                    </Link>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
});

export default BoardRoute;
