import React, { useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";

import Input from "../../../components/Input";

import { QUERY_ENTITY_WITH_DESK } from "../../../config/graphql/query";
import {
  ADD_ENTITY_DESK,
  UPDATE_ENTITY_DESK,
  DELETE_ENTITY_DESK,
} from "../../../config/graphql/mutation";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";

const EntityDeskCreate = React.memo(() => {
  const {
    params: { id: entityId },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation(["entities", "common"]);

  const [show, setShow] = useToggle(false);
  const history = useHistory();

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email(t("entities:floorPlan.entityDesk.yup.email")),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: entityDeskData } = useQuery(QUERY_ENTITY_WITH_DESK, {
    skip: !entityId,
    variables: {
      id: entityId,
    },
    onCompleted: ({ entity: { desk } }) => {
      if (!desk) {
        return;
      }
      return methods.reset(desk);
    },
  });

  const desk = entityDeskData?.entity?.desk;

  const [onUpdate] = useMutation(UPDATE_ENTITY_DESK, {
    refetchQueries: [
      {
        query: QUERY_ENTITY_WITH_DESK,
        variables: {
          id: entityId,
        },
      },
    ],
  });
  const [onCreate] = useMutation(ADD_ENTITY_DESK, {
    refetchQueries: [
      {
        query: QUERY_ENTITY_WITH_DESK,
        variables: {
          id: entityId,
        },
      },
    ],
  });
  const [onDelete] = useMutation(DELETE_ENTITY_DESK, {
    refetchQueries: [
      {
        query: QUERY_ENTITY_WITH_DESK,
        variables: {
          id: entityId,
        },
      },
    ],
  });

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(false);

      history.replace(`/entity/${entityId}/view/desk`);

      return onDelete({
        variables: { id: desk?.id },
      })
        .then(() => {
          toast.success(t("entities:floorPlan.entityDesk.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [setShow, history, entityId, onDelete, desk, t]
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  const onSubmit = (input: IEntityDesk) => {
    if (!!desk) {
      return onUpdate({ variables: { input: { ...input, id: desk.id } } })
        .then(() => {
          history.replace(`/entity/${entityId}/view/desk`);
          toast.success(t("entities:floorPlan.entityDesk.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    return onCreate({ variables: { input: { ...input, entity: entityId } } })
      .then(() => {
        toast.success(t("entities:floorPlan.entityDesk.toast.created"));
        history.replace(`/entity/${entityId}/view/desk`);
      })
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row p-3" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 col-xl-6">
            <div className="form-group">
              <label htmlFor="email">
                {t("entities:floorPlan.entityDesk.form.email")}
              </label>
              <Input name="email" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {!!desk?.id && (
              <button onClick={onBeforeRemove} className="btn btn-danger ml-3">
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("entities:floorPlan.entityDesk.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("entities:floorPlan.entityDesk.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default EntityDeskCreate;
