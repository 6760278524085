import React, { useMemo } from "react";

import {
  ApolloClient,
  ApolloProvider as Provider,
  from as createLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { cache } from "./cache";
import { useHistory } from "react-router-dom";

export const AuthLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("Authorization");
  const client = localStorage.getItem("Client");
  const Language = localStorage.getItem("Locale");

  return {
    headers: {
      ...headers,
      "Accept-Language": Language,
      Authorization: token ? `Bearer ${token}` : "",
      ClientId: client,
    },
  };
});

export const ApolloProvider = React.memo(({ children }) => {
  const history = useHistory();

  const client = useMemo(() => {
    const ErrorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            //@ts-ignore
            switch (error?.extensions?.code) {
              case "UNAUTHENTICATED": {
                window.localStorage.removeItem("Authorization");

                history.replace({
                  pathname: "/login",
                  state: { from: history.location },
                });

                return forward(operation);
              }

              default: {
                break;
              }
            }
          }
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          // if you would also like to retry automatically on
          // network errors, we recommend that you use
          // @apollo/client/link/retry
        }
      }
    );

    const link = createLink([
      AuthLink,
      ErrorLink,
      new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` }),
    ]);

    return new ApolloClient({
      link,
      cache,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider client={client}>{children}</Provider>;
});
