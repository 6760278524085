import { gql } from "@apollo/client";

export const FRAGMENT_FILE = gql`
  fragment FragmentFile on File {
    id
    filename
    path
    absolutePath
  }
`;

export const FRAGMENT_ENTITY = gql`
  fragment FragmentEntity on Entity {
    id
    type
    title
    propertiesCount
    properties {
      id
      ... on Company {
        type
        title
      }
    }
    ... on Building {
      address
      city
      zipCode
      country
      entitiesCount
      logo {
        ...FragmentFile
      }
      entities {
        id
        type
      }
    }
    ... on Floor {
      entitiesCount
      plan {
        id
        path
        absolutePath
        filename
      }
      parent {
        id
        type
      }
      entities {
        id
        type
        ... on Space {
          geoJSON
        }
      }
    }
    ... on Sector {
      entitiesCount
      parent {
        id
        type
      }
    }
    ... on Space {
      geoJSON
      parent {
        id
        type
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const FRAGMENT_ENTITY_PARENT = gql`
  fragment FragmentEntityParent on Entity {
    ... on Floor {
      parent {
        id
        type
      }
    }
    ... on Sector {
      parent {
        id
        type
      }
    }
    ... on Space {
      parent {
        id
        type
      }
    }
  }
`;

export const FRAGMENT_ENTITY_PARENTS = gql`
  fragment FragmentEntityParents on Entity {
    ... on Floor {
      parents {
        id
        title
      }
    }
    ... on Sector {
      parents {
        id
        title
      }
    }
    ... on Space {
      parents {
        id
        title
      }
    }
  }
`;

export const FRAGMENT_ENTITY_BUILDING = gql`
  fragment FragmentEntityBuilding on Entity {
    id
    type
    ... on Building {
      title
      address
      city
      zipCode
      country
    }
  }
`;

export const FRAGMENT_PROPERTY = gql`
  fragment FragmentProperty on Property {
    id
    ... on Company {
      type
      createdAt
      updatedAt
      title
      description
      address
      city
      zipCode
      country
      shortenURL
      postbox
      phone
      fax
      website
      email
      facebook
      twitter
      linkedIn
      emailLanguage
    }
    ... on Doctor {
      type
      title
    }
  }
`;

export const FRAGMENT_COMPANY = gql`
  fragment FragmentCompany on Company {
    id
    type
    createdAt
    updatedAt
    title
    description
    address
    city
    zipCode
    country
    shortenURL
    postbox
    phone
    fax
    website
    email
    facebook
    twitter
    linkedIn
    emailLanguage
  }
`;

export const FRAGMENT_CLIENT = gql`
  fragment FragmentClient on Client {
    id
    title
    email
    domain
    description
  }
`;

export const FRAGMENT_CLIENT_TOKEN = gql`
  fragment FragmentClientToken on ClientToken {
    id
    title
    token
    development
    ip
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_USER = gql`
  fragment FragmentUser on User {
    id
    createdAt
    firstName
    lastName
    email
    language
    roles
    companies {
      id
      title
    }
  }
`;

export const FRAGMENT_NAMEBOARD = gql`
  fragment FragmentNameboard on Nameboard {
    id
    title
    template
  }
`;

export const FRAGMENT_NAMEBOARD_SETTINGS = gql`
  fragment FragmentNameboardSettings on NameboardSettings {
    layout
    plugins {
      feed {
        url
      }
      twitter {
        username
      }
      externalVisitors {
        id
        url
      }
    }
    hardware {
      scannerPosition
      dispensers {
        id
        name
      }
      validators {
        id
        name
      }
    }
    support {
      email
      phone
    }
  }
`;

export const FRAGMENT_VISIONECT = gql`
  fragment FragmentVisionect on Visionect {
    id
    title
    template
    rotation
    device {
      Uuid
      SessionId
      Displays {
        Id
        Rotation
      }
      Options {
        Name
      }
      Status {
        Battery
        ConnectivityUsed
      }
      State
    }
  }
`;

export const FRAGMENT_ERROR = gql`
  fragment FragmentError on ErrorLog {
    id
    user {
      id
      firstName
      lastName
    }
    method
    endpoint
    payload
    ip
    rawHeaders
    errorMessage
    errorStack
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_ENTITY_DESK = gql`
  fragment FragmentEntityDesk on EntityDesk {
    id
    email
    entity {
      id
      type
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_VISITOR = gql`
  fragment FragmentVisitor on Visitor {
    id
    createdAt
    updatedAt
    company {
      id
      title
    }
    building {
      id
      title
    }
    host {
      id
      email
      name
    }
    invitedBy {
      id
      firstName
      lastName
    }
    editedBy {
      id
    }
    badge
    name
    email
    phone
    dateStart
    allDay
    meeting {
      id
      title
      description
      dateEnd
      attendees {
        id
        name
      }
    }
    checkedInAt
    checkedOutAt
  }
`;
export const FRAGMENT_LOG = gql`
  fragment FragmentLog on Log {
    id
    user {
      id
      firstName
      lastName
    }
    method
    endpoint
    payload
    ip
    rawHeaders
    responseDuration
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_MEETING = gql`
  fragment FragmentMeeting on Meeting {
    id
    title
    description
    dateStart
    dateEnd
    externalId
    emailLanguage
    attendees {
      id
      name
      email
      badge
      qrPass {
        id
        fallbackCode
      }
    }
    createdAt
    updatedAt
    company {
      id
      title
    }
    building {
      id
      title
    }
    host {
      id
      email
      name
      qrPass {
        id
        fallbackCode
      }
    }
  }
`;

export const FRAGMENT_OUTLOOK_SUBSCRIPTION = gql`
  fragment FragmentOutlookSubscription on OutlookSubscription {
    id
    subscriptionId
    title
    description
    email
    createdAt
    updatedAt
    homeTenantId
    resource
    changeType
    clientState
    expirationDateTime
    building {
      id
      title
    }
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_TWILIO_CLIENT = gql`
  fragment FragmentTwilioClient on TwilioClient {
    id
    createdAt
    updatedAt
    title
    description
    accountSid
    apiKey
    apiSecret
    applicationId
    callerId
    # client {
    #   id
    #   title
    # }
  }
`;

export const FRAGMENT_CLIENT_SENDGRID = gql`
  fragment FragmentClientSendGrid on SendGridClient {
    id
    createdAt
    updatedAt
    title
    description
    apiKey
    senderEmail
    senderName
  }
`;

export const FRAGMENT_CLIENT_SENDGRID_TEMPLATE = gql`
  fragment FragmentClientSendGridTemplate on EmailTemplate {
    id
    createdAt
    updatedAt
    title
    template
    templateId
  }
`;
