import React from "react";

import clsx from "clsx";

import {
  useRouteMatch,
  useParams,
  NavLink,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { QUERY_CLIENT } from "../../../config/graphql/query";

import Tokens from "./ClientTokensList";
import Integrations from "./ClientIntegration";
import TwilioClients from "./TwilioClients";
import InformationForm from "./ClientInformation";
import ClientSendGridList from "./ClientSendGridList";
import ClientSendGridTemplateList from "./ClientSendGridTemplateList";

const ClientCreate = React.memo(() => {
  const match = useRouteMatch();
  const location = useLocation();

  const { t } = useTranslation(["client", "common"]);

  const { clientId } = useParams<{ clientId: string }>();

  const { data } = useQuery(QUERY_CLIENT, {
    skip: !clientId,
    variables: { id: clientId },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/client"}>
              {t("client:client.clientCreate.nav.clients")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.client?.title ?? "Client"}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("client:client.clientCreate.nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId,
            })}
            to={`${match.url}/token`}
          >
            {t("client:client.clientCreate.nav.tokens")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId,
            })}
            to={`${match.url}/twilio`}
          >
            {t("client:client.clientCreate.nav.twilio")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId,
            })}
            to={`${match.url}/integration`}
          >
            {t("client:client.clientCreate.nav.integration")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId,
            })}
            to={`${match.url}/sendgrid`}
            isActive={() => {
              return location.pathname.includes("sendgrid");
            }}
          >
            {t("client:client.clientCreate.nav.sendgrid")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <InformationForm />
            </div>
          </Route>
          <Route exact path={`${match.path}/token`}>
            <div className="tab-pane active" role="tabpanel">
              <Tokens />
            </div>
          </Route>
          <Route exact path={`${match.path}/twilio`}>
            <div className="tab-pane active" role="tabpanel">
              <TwilioClients />
            </div>
          </Route>
          <Route exact path={`${match.path}/integration`}>
            <div className="tab-pane active" role="tabpanel">
              <Integrations />
            </div>
          </Route>
          <Route path={`${match.path}/sendgrid`}>
            <div className="tab-pane active" role="tabpanel">
              <ClientSendGridList />
            </div>
          </Route>
          <Route path={`${match.path}/sendgrid-templates`}>
            <div className="tab-pane active" role="tabpanel">
              <ClientSendGridTemplateList />
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default ClientCreate;
