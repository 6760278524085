import React, { useContext } from "react";

/* packages */
import { useHistory } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";

/* graphql */
import { QUERY_USER_LOGIN, QUERY_AUTH } from "../config/graphql/query";

type AuthValue = {
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
};

const AuthContext = React.createContext({});

const { Consumer, Provider } = AuthContext;

export function useAuth() {
  return useContext(AuthContext) as AuthValue;
}

const AuthProvider = React.memo(({ children }) => {
  const { push } = useHistory();

  const client = useApolloClient();

  function login(email: string, password: string) {
    return client
      .mutate({
        mutation: QUERY_USER_LOGIN,
        variables: { input: { email, password } },
      })
      .then((data) => {
        const {
          data: {
            login: { token: userToken },
          },
        } = data;

        window.localStorage.setItem("Authorization", userToken);
        // window.localStorage.setItem("Client", "1");

        client.cache.writeQuery({
          query: QUERY_AUTH,
          data: {
            token: userToken,
            // clientId: 1,
          },
        });

        return data;
      });
  }

  async function logout() {
    window.localStorage.removeItem("Client");
    window.localStorage.removeItem("Authorization");

    await client.clearStore();

    return push("/login");
  }

  return (
    <Provider
      value={{
        login,
        logout,
      }}
    >
      {children}
    </Provider>
  );
});

export { AuthContext, AuthProvider, Consumer as AuthConsumer };

export default AuthContext;
