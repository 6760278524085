import React, { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  useRouteMatch,
  Route,
  Switch,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";

import clsx from "clsx";
import qs from "query-string";
import dayjs from "dayjs";

import { QUERY_LOGS } from "../../config/graphql/query";
import { DELETE_LOG, DELETE_MANY_LOGS } from "../../config/graphql/mutation";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import useDebounce from "react-use/lib/useDebounce";
import { useTranslation } from "react-i18next";

import Log from "./Log/index";
import Pagination from "../../components/Pagination";

const limit = 10;

const TableRow = ({ item }: { item: ILog }) => {
  const { id, createdAt, responseDuration, endpoint } = item;
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["logs", "common"]);

  const [onDelete] = useMutation(DELETE_LOG, {
    refetchQueries: [{ query: QUERY_LOGS }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();
    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th>{endpoint}</th>
        <td
          className={clsx("", {
            "bg-warning fw-bold": parseInt(responseDuration) >= 1000,
            "bg-danger fw-bold": parseInt(responseDuration) >= 3000,
          })}
        >
          {responseDuration}
        </td>
        <th className="text-nowrap">{createdAt}</th>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`log/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("logs:logs.modal.title", { count: 1 })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("logs:logs.modal.body", { id: item.id, count: 1 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const generateOrFilter = (fields: string[], value: string) =>
  fields.map((key) => ({
    [key]: {
      REGEX: value,
      OPTIONS: "i",
    },
  }));

const Logs = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["logs", "common"]);

  const [onDeleteMany] = useMutation(DELETE_MANY_LOGS, {
    variables: {
      filter: {
        createdAt: { LTE: dayjs().subtract(3, "month").toISOString() },
      },
    },
    refetchQueries: [{ query: QUERY_LOGS }],
  });

  const onBeforeDelete = () => {
    onDeleteMany();
    return setVisible(false);
  };

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search]
  );
  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query?.page || 0, 1), [query]);

  const variables = useMemo(() => {
    let nextVariables = {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      sort: {
        createdAt: "DESC",
      },
      filter: {},
    };

    if (query.search) {
      const { filter: prevFilter } = nextVariables;
      nextVariables = {
        ...nextVariables,
        filter: {
          ...prevFilter,
          OR: generateOrFilter(
            ["method", "endpoint", "payload", "rawHeaders", "ip", "rawHeaders"],
            query.search
          ),
        },
      };
    }

    return nextVariables;
  }, [page, query.search]);

  const { data } = useQuery<{
    logs?: Array<ILog>;
    logsCount: number;
  }>(QUERY_LOGS, {
    fetchPolicy: "network-only",
    variables,
  });
  const logs = data?.logs ?? [];
  const logsCount = data?.logsCount ?? 0;

  const renderLog = useCallback(
    (item: ILog) => <TableRow key={item.id} item={item} />,
    []
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({ page: 1, search: search }),
      });
    },
    1000,
    [search]
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("logs:logs.nav.logs")}
          </li>
        </ol>
      </nav>
      <div className="d-flex m-2">
        <input
          className="pl-2"
          placeholder={t("common:search")}
          value={search}
          onChange={({ target: { value } }) => {
            setSearch(value);
          }}
        />
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>{t("logs:logs.th.endpoint")}</th>
              <th>{t("logs:logs.th.response")}</th>
              <th>{t("logs:logs.th.created")}</th>
              <th scope="col">{t("logs:logs.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{logs.map(renderLog)}</tbody>
        </table>
      </div>
      <Pagination documentsCount={logsCount} limit={limit} />
      <hr />
      <span>{t("logs:logs.span", { count: 3 })}</span>

      <Button
        size="sm"
        variant="danger"
        className="mr-2 ml-3"
        onClick={() => setVisible(true)}
      >
        {t("common:delete")}
      </Button>
      <hr />
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("logs:logs.modal.title", { count: 3 })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("logs:logs.modal.body", { count: 3 })}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

const LogsRoute = React.memo(() => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Logs />
      </Route>
      <Route path={`${path}/:id`}>
        <Log />
      </Route>
    </Switch>
  );
});

export default LogsRoute;
