import React from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { Card, Accordion } from "react-bootstrap";

import { QUERY_VISITOR } from "../../../config/graphql/query";
import dayjs from "dayjs";

const VisitorLog = React.memo(() => {
  const {
    params: { id },
  } = useRouteMatch();

  const { data } = useQuery<{ visitor?: IVisitorLog }>(QUERY_VISITOR, {
    skip: !id,
    variables: { id },
  });

  return (
    <div className="container-fluid">
      <Card className="m-3 p-3">
        Created at:{" "}
        {dayjs(data?.visitor?.createdAt).format(" DD-MM-YYYY / hh:mm:ss") ?? ""}
      </Card>
      <Accordion defaultActiveKey="0" className="m-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Visitor details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Text>
                <strong>Name:</strong> {data?.visitor?.name ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Email:</strong> {data?.visitor?.email ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Phone:</strong> {data?.visitor?.phone ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Checked in at:</strong>{" "}
                {data?.visitor?.checkedInAt
                  ? dayjs(data?.visitor?.checkedInAt).format(
                      " DD-MM-YYYY / hh:mm"
                    )
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>Checked out at:</strong>{" "}
                {data?.visitor?.checkedInAt
                  ? dayjs(data?.visitor?.checkedOutAt).format(
                      " DD-MM-YYYY / hh:mm"
                    )
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>All day:</strong> {data?.visitor?.allDay ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Host details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card.Text>
                <strong>Name:</strong> {data?.visitor?.host?.name ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Email:</strong> {data?.visitor?.host?.email ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Invited by:</strong>{" "}
                {data?.visitor?.invitedBy?.firstName ?? ""}{" "}
                {data?.visitor?.invitedBy?.lastName ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Edited by:</strong>{" "}
                {data?.visitor?.editedBy?.firstName ?? ""}{" "}
                {data?.visitor?.editedBy?.lastName ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Company:</strong> {data?.visitor?.company?.title ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Building:</strong>{" "}
                {data?.visitor?.building?.title ?? ""}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Meeting details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <Card.Text>
                <strong>Title:</strong> {data?.visitor?.meeting?.title ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Description:</strong>{" "}
                {data?.visitor?.meeting?.description ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Start date:</strong>
                {data?.visitor?.dateStart
                  ? dayjs(data?.visitor?.dateStart).format(
                      " DD-MM-YYYY / hh:mm"
                    )
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>End date:</strong>
                {data?.visitor?.meeting?.dateEnd
                  ? dayjs(data?.visitor?.meeting?.dateEnd).format(
                      " DD-MM-YYYY / hh:mm"
                    )
                  : " /"}
              </Card.Text>
              <Card.Text>
                <strong>Attendees:</strong>{" "}
                {data?.visitor?.meeting?.attendees.map((attendee) => {
                  return <span>{attendee.name}; </span>;
                }) ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Badge details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Card.Text>
                <strong>Badge id:</strong> {data?.visitor?.badge ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Returned:</strong> {data?.visitor?.badge ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
});

export default VisitorLog;
