import React, { Fragment, useMemo, RefObject } from "react";
import { useFormContext } from "react-hook-form";

import clsx from "clsx";
import get from "lodash/get";

import { useQuery } from "@apollo/client";

import { QUERY_LOCALE } from "../config/graphql/query";

type Ref = HTMLTextAreaElement;

interface StaticComponents
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<Ref>> {
  Translatable: React.FC<Props>;
}
interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

// @ts-ignore
const TextArea: React.FC<Props> & StaticComponents = React.forwardRef<
  Ref,
  Props
>((props: Props, ref) => {
  const { name } = props;

  const { register, errors } = useFormContext();

  // const theRef = useMemo<React.RefObject<HTMLTextAreaElement>>((e) => {
  //   register()(e);

  //   if (ref) {
  //     ref.current = e;
  //   }
  // }, []);

  return (
    <Fragment>
      <textarea
        rows={5}
        {...props}
        ref={ref || register}
        // ref={theRef}
        className={clsx("form-control", {
          "is-invalid": !!errors[name],
        })}
      />
      {!!errors[name] && (
        <div className="invalid-feedback">{errors[name].message}</div>
      )}
    </Fragment>
  );
});

const Translatable: React.FC<Props> = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name } = props;

    const { register, errors } = useFormContext();

    const { data } = useQuery(QUERY_LOCALE);

    const locale = data?.locale ?? "en";

    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id={`translate-${name}`}>
            {`${locale}`}
          </span>
        </div>
        <textarea
          rows={5}
          {...props}
          aria-describedby={`translate-${name}`}
          ref={ref || register}
          className={clsx("form-control", {
            "is-invalid": !!errors[name],
          })}
        />
        {!!get(errors, name) && (
          <div className="invalid-feedback">
            {get(errors, `${name}.message`)}
          </div>
        )}
      </div>
    );
  }
);

TextArea.Translatable = Translatable;

export default TextArea;
