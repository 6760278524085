import React, { useMemo, useCallback, useEffect, useRef } from "react";

import * as yup from "yup";

import map from "lodash/map";

import CreatableSelect from "react-select/creatable";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { useQuery, useMutation } from "@apollo/client";
import { FormProvider, useForm, Controller } from "react-hook-form";

import Input from "../../../../components/Input";

import { useEntity } from "../../../../context/Entity";
import { EntityType } from "../../../../config/const/entity";
import { PropertyType } from "../../../../config/const/property";

/* config */
import {
  QUERY_ENTITY,
  QUERY_ENTITIES,
  QUERY_COMPANIES,
} from "../../../../config/graphql/query";
import {
  CREATE_SPACE,
  CREATE_COMPANY,
  CREATE_ENTITY_PROPERTIES,
  UPDATE_SPACE,
} from "../../../../config/graphql/mutation";

const EntityForm = React.memo(
  ({ entity, onHide }: { entity: Partial<ISpace>; onHide: () => any }) => {
    const titleInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      titleInput?.current?.focus?.();
    }, []);

    const parent = useEntity();

    const schema = useMemo(
      () =>
        yup.object().shape({
          parent: yup.string().required("Parent is required"),
          title: yup.string().required("Title is required"),
          geoJSON: yup.object(),
          properties: yup.array().of(
            yup.object().shape({
              id: yup.string(),

              title: yup.string(),
            })
          ),
        }),
      []
    );

    const [onCreateCompany, { loading: creatingCompany }] =
      useMutation(CREATE_COMPANY);

    const refetchQueries = useMemo(
      () => [
        {
          query: QUERY_ENTITIES,
          variables: {
            filter: {
              parent: parent?.id || null,
            },
          },
        },
        {
          query: QUERY_ENTITY,
          variables: {
            id: parent?.id,
          },
        },
      ],
      [parent]
    );

    const [onUpdateSpace] = useMutation(UPDATE_SPACE, {
      awaitRefetchQueries: true,
      refetchQueries,
    });

    const [onCreateSpace, { loading: creatingEntity }] = useMutation(
      CREATE_SPACE,
      {
        awaitRefetchQueries: true,
        refetchQueries,
      }
    );

    const [onEntityAttachProperties, { loading: updatingProperties }] =
      useMutation(CREATE_ENTITY_PROPERTIES, {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_ENTITIES,
            variables: {
              filter: {
                parent: parent?.id || null,
              },
            },
          },
        ],
      });

    const { data: propertiesData, loading: loadingProperties } =
      useQuery(QUERY_COMPANIES);

    const options = useMemo(
      () => propertiesData?.companies ?? [],
      [propertiesData]
    );

    const onCreateOption = useCallback((inputValue: any) => {
      onCreateCompany({
        variables: {
          input: {
            type: PropertyType.Company,
            title: inputValue,
          },
        },
      })
        .then(({ data: { addCompany } }) => {
          const prevProperties = methods.getValues("properties") || [];

          methods.setValue("properties", [
            //@ts-ignore
            ...prevProperties,
            addCompany,
          ]);
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const methods = useForm({
      resolver: yupResolver(schema),
      shouldFocusError: false,
      defaultValues: {
        properties: [],
        ...entity,
        parent: parent.id,
      },
    });

    // ! Register inputs that are required
    useEffect(() => {
      methods.register("parent");

      methods.register("geoJSON");
      methods.setValue("geoJSON", entity?.geoJSON);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = useCallback(
      (
        values: TEntityInput & {
          type: string;
          parent?: string;
          properties?: ICompany[];
        }
      ) => {
        if (creatingCompany || creatingEntity || updatingProperties) {
          return;
        }

        const { properties, ...rest } = values;

        if (entity?.id) {
          return onUpdateSpace({
            variables: {
              input: { ...rest, id: entity.id },
            },
          })
            .then(() => {
              return onEntityAttachProperties({
                variables: {
                  input: {
                    id: entity.id,
                    properties: map(properties, "id"),
                  },
                },
              })
                .then(() => {
                  toast.success("Entity updated and properties attached");

                  return onHide();
                })
                .catch((error) => {
                  toast.error(
                    error?.networkError?.result?.errors?.[0]?.message ??
                      error?.message
                  );
                });
            })
            .catch((error) => {
              toast.error(
                error?.networkError?.result?.errors?.[0]?.message ??
                  error?.message
              );
            });
        }

        onCreateSpace({ variables: { input: rest } })
          .then(
            ({
              data: {
                addSpace: { id: createdEntityId },
              },
            }) => {
              if (!(properties && !!properties.length)) {
                toast.success("Entity created");

                return onHide();
              }

              return onEntityAttachProperties({
                variables: {
                  input: {
                    id: createdEntityId,
                    properties: map(properties, "id"),
                  },
                },
              })
                .then(() => {
                  toast.success("Entity created and properties attached");

                  return onHide();
                })
                .catch((error) => {
                  toast.error(
                    error?.networkError?.result?.errors?.[0]?.message ??
                      error?.message
                  );
                });
            }
          )
          .catch((error) => {
            toast.error(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message
            );
          });
      },
      [
        creatingCompany,
        creatingEntity,
        updatingProperties,
        entity,
        onCreateSpace,
        onUpdateSpace,
        onEntityAttachProperties,
        onHide,
      ]
    );

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">Title *</label>
              <Input
                name="title"
                ref={(e) => {
                  methods.register(e);
                  titleInput.current = e;
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="properties">Company</label>
              <Controller
                control={methods.control}
                name="properties"
                render={({ onChange, ...props }) => (
                  <CreatableSelect
                    isMulti
                    isLoading={loadingProperties}
                    aria-describedby="properties-help"
                    // @ts-ignore
                    getOptionLabel={({ title }) => title}
                    // @ts-ignore
                    getOptionValue={({ id }) => id}
                    getNewOptionData={(_inputValue, optionLabel) => ({
                      id: "new",
                      title: optionLabel,
                    })}
                    options={options}
                    onCreateOption={onCreateOption}
                    onChange={(value) => onChange(value || [])}
                    {...props}
                  />
                )}
              />
              <small
                id="properties-help"
                className="form-text text-muted small"
              >
                Yuo can create or attach an existing company
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <input
              disabled={creatingCompany || creatingEntity || updatingProperties}
              type="submit"
              className="btn btn-primary"
              value="Save Changes"
            />
          </Modal.Footer>
        </form>
      </FormProvider>
    );
  }
);

export default EntityForm;
