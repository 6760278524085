import React, { useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import {
  QUERY_ERROR_LOG,
  QUERY_ERRORS_LOG,
} from "../../../config/graphql/query";
import { DELETE_ERROR_LOG } from "../../../config/graphql/mutation";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ErrorLog = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { t } = useTranslation(["errorLogs", "common"]);

  const { data } = useQuery<{ errorLog?: IErrorLog }>(QUERY_ERROR_LOG, {
    skip: !id,
    variables: { id },
  });

  const [onDelete] = useMutation(DELETE_ERROR_LOG);

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      history.replace("/errorLog");

      return onDelete({ variables: { id } })
        .then(() => {
          toast.success(t("errorLogs:errorLog.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [history, id, onDelete, t]
  );

  const onBeforeDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/errorLog"}>
              {t("errorLogs:errorLog.nav.errorLogs")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("errorLogs:errorLog.nav.errorLog")}
          </li>
        </ol>
      </nav>
      <Card className="mb-3 p-3">
        {t("errorLogs:errorLog.card.created")}
        {data?.errorLog?.createdAt ?? ""}
      </Card>
      <Card className="mb-3 p-3">
        {t("errorLogs:errorLog.card.errorMessage")}
        {data?.errorLog?.errorMessage ?? ""}
      </Card>
      <Card className="mb-3 p-3">IP: {data?.errorLog?.ip ?? ""}</Card>

      {data?.errorLog?.user && (
        <>
          <Card className="mb-3 p-3">
            {t("errorLogs:errorLog.card.firstName")}
            {data?.errorLog?.user?.firstName ?? ""}
          </Card>
          <Card className="mb-3 p-3">
            {t("errorLogs:errorLog.card.lastName")}
            {data?.errorLog?.user?.lastName ?? ""}
          </Card>
        </>
      )}

      <Accordion defaultActiveKey="0" className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {t("errorLogs:errorLog.card.endpoint")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body>{data?.errorLog?.endpoint ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {t("errorLogs:errorLog.card.rawHeaders")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {map(
                JSON.parse(data?.errorLog?.rawHeaders ?? "[]"),
                (header, index: number) => (
                  <p className={index % 2 === 0 ? "mb-0" : ""}>{header}</p>
                )
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            {t("errorLogs:errorLog.card.payload")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <pre>
                {JSON.stringify(
                  JSON.parse(data?.errorLog?.payload ?? "{}"),
                  null,
                  2
                )}
              </pre>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            {t("errorLogs:errorLog.card.errorStack")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="3">
            <Card.Body>{data?.errorLog?.errorStack ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {id && (
        <button onClick={onBeforeDelete} className="btn btn-danger ml-3">
          {t("common:delete")}
        </button>
      )}
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("errorLogs:errorLog.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("errorLogs:errorLog.modal.body", { id: id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ErrorLog;
