import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import en from "./en.json";
import nl from "./nl.json";

function onLoadComplete() {}

i18n.use(initReactI18next).init(
  {
    //@ts-ignore
    resources: { en, nl },

    debug: process.env.NODE_ENV === "development",
    lng: "en",
    fallbackLng: "en",

    keySeparator: ".",

    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        switch (format) {
          case "uppercase": {
            return value.toUpperCase();
          }

          default: {
            return value;
          }
        }
      },
    },
  },
  onLoadComplete
);

export default i18n;
