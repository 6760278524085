import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";
import { useRouteMatch, useParams, Link } from "react-router-dom";

import { QUERY_CLIENT_WITH_TWILIO_CLIENTS } from "../../../config/graphql/query";

const TableRow = ({ item }: { item: IClientTwilio }) => {
  const { url } = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, description } = item;

  return (
    <>
      <tr>
        <td>{id}</td>
        <td>{title}</td>
        <td>{description}</td>
        <td>
          <div className="d-flex">
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const ClientTokens = React.memo(() => {
  const { url } = useRouteMatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery<{ client?: { twilioClients?: IClientTwilio[] } }>(
    QUERY_CLIENT_WITH_TWILIO_CLIENTS,
    {
      variables: {
        id: clientId,
      },
      fetchPolicy: "network-only",
    }
  );

  const twilioClients = data?.client?.twilioClients ?? [];

  const renderListItem = useCallback(
    (item: IClientTwilio) => <TableRow key={item.id} item={item} />,
    []
  );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("client:client.tokens.button.add")}
          </Link>
        </div>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("client:client.tokens.th.title")}</th>
              <th scope="col">{t("client:client.tokens.th.development")}</th>
              <th scope="col">{t("client:client.tokens.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{twilioClients.map(renderListItem)}</tbody>
        </Table>
      </>
    </div>
  );
});

export default ClientTokens;
