import React, { useCallback, useMemo } from "react";

import qs from "query-string";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import { useMutation, useQuery } from "@apollo/client";

import { QUERY_COMPANIES } from "../../config/graphql/query";
import { DELETE_COMPANY } from "../../config/graphql/mutation";
import {
  Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import CompanyPage from "./Company";
import Pagination from "../../components/Pagination";

const limit = 10;

const TableRow = ({ item }: { item: ICompany }) => {
  const { t } = useTranslation(["company", "common"]);

  const { id, createdAt, updatedAt, title } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_COMPANY, {
    refetchQueries: [{ query: QUERY_COMPANIES }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {title}
        </th>
        <td>{dayjs(createdAt).format("DD-MM-YYYY")}</td>
        <td>{dayjs(updatedAt).format("DD-MM-YYYY")}</td>
        <td className="text-nowrap">
          <Button
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={() => setVisible(true)}
          >
            {t("common:delete")}
          </Button>
          <Link to={`/company/${id}`}>
            <Button
              size="sm"
              variant="primary"
              onClick={() => setVisible(false)}
            >
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("company:companies.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("company:companies.modal.body", { title: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Companies = React.memo(() => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation(["company", "common"]);

  const query = useMemo(
    () =>
      qs.parse(history.location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [history.location.search]
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const { data } = useQuery<{
    companies?: Array<ICompany>;
    companiesCount: number;
  }>(QUERY_COMPANIES, {
    onError: console.log,
    fetchPolicy: "network-only",
    variables: {
      sort: {
        title: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.search
        ? {
            filter: {
              title: {
                REGEX: query.search,
                OPTIONS: "i",
              },
            },
          }
        : {}),
    },
  });

  const companies = data?.companies ?? [];
  const companiesCount = data?.companiesCount ?? 0;

  const renderCompany = useCallback(
    (item: ICompany) => <TableRow key={item.id} item={item} />,
    []
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("company:companies.companies")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-between align-items-center my-4">
        <form className="form-inline">
          <div className="form-group">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("company:companies.input.search.placeholder")}
              onChange={({ target: { value } }) =>
                history.push({
                  search: qs.stringify({ search: value }),
                })
              }
            />
          </div>
        </form>
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary"
        >
          {t("company:companies.add")}
        </Link>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t("company:companies.th.title")}</th>
              <th scope="col">{t("company:companies.th.created")}</th>
              <th scope="col">{t("company:companies.th.updated")}</th>
              <th scope="col">{t("company:companies.th.action")}</th>
            </tr>
          </thead>
          <tbody>{companies.map(renderCompany)}</tbody>
        </table>
      </div>
      <Pagination documentsCount={companiesCount} limit={limit} />
    </div>
  );
});

const BuildingRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Companies />
      </Route>
      <Route exact path={`${path}/new`}>
        <CompanyPage />
      </Route>
      <Route path={`${path}/:id`}>
        <CompanyPage />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default BuildingRoute;
