import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import clsx from "clsx";
import get from "lodash/get";

import { useQuery } from "@apollo/client";

import { QUERY_LOCALE } from "../config/graphql/query";

type Ref = HTMLInputElement;

interface StaticComponents
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<Ref>> {
  Translatable: React.FC<Props>;
}
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

// @ts-ignore
const Input: React.FC<Props> & StaticComponents = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name } = props;

    const { register, errors } = useFormContext();

    return (
      <Fragment>
        <input
          {...props}
          ref={ref || register}
          className={clsx("form-control", {
            "is-invalid": !!get(errors, name),
          })}
        />
        {!!get(errors, name) && (
          <div className="invalid-feedback">
            {get(errors, `${name}.message`)}
          </div>
        )}
      </Fragment>
    );
  }
);

const Translatable: React.FC<Props> = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name } = props;

    const { register, errors } = useFormContext();

    const { data } = useQuery(QUERY_LOCALE);

    const locale = data?.locale ?? "en";

    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id={`translate-${name}`}>
            {`${locale}`}
          </span>
        </div>
        <input
          {...props}
          aria-describedby={`translate-${name}`}
          ref={ref || register}
          className={clsx("form-control", {
            "is-invalid": !!get(errors, name),
          })}
        />
        {!!get(errors, name) && (
          <div className="invalid-feedback">
            {get(errors, `${name}.message`)}
          </div>
        )}
      </div>
    );
  }
);

Input.Translatable = Translatable;

export default Input;
