import React from "react";

import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { QUERY_COMPANY_VISIONECT } from "../../../../config/graphql/query";

const WayfindingsRoute = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["company", "common"]);

  const { id } = useParams<{ id: string }>();

  const { data } = useQuery(QUERY_COMPANY_VISIONECT, {
    skip: !id,
    variables: {
      id,
      filter: { property: id },
    },
  });

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">{t("company:wayfindings.th.title")}</th>
            <th scope="col">{t("company:wayfindings.th.entity")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.company?.visionects?.map(({ id, items }: IVisionect) =>
            items.map((i) => (
              <tr
                onClick={() => history.push(`/wayfinding/${id}/item/${i.id}`)}
              >
                <td>{i.entity.title}</td>
                <td>{i.entity.type}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
});

export default WayfindingsRoute;
