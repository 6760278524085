import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

// Context
import { useAuth } from "../../context/Auth";
import { toast } from "react-toastify";

const Login = React.memo(() => {
  const [email, setEmail] = useState(
    process.env.NODE_ENV === "development" ? "webmaster@futureforward.rs" : ""
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "admin" : ""
  );

  const { login } = useAuth();

  const { t } = useTranslation(["login", "common"]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      return login(email, password).catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
    },
    [email, login, password]
  );

  return (
    <div className="row vh-100 justify-content-center align-items-center">
      <div className="col-6">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">{t("login:form.email.label")}</label>
            <input
              id="email"
              // type="email"
              className="form-control"
              placeholder={t("login:form.email.placeholder")}
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t("login:form.password.label")}</label>
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder={t("login:form.password.placeholder")}
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {t("common:submit")}
          </button>
        </form>
      </div>
    </div>
  );
});

export default Login;
