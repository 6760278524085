import React, { useCallback } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import useToggle from "react-use/lib/useToggle";

import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { QUERY_USERS } from "../../config/graphql/query";
import { DELETE_USER } from "../../config/graphql/mutation";
import { Route, Switch, useRouteMatch, Redirect, Link } from "react-router-dom";

import UserCreate from "./User/index";

const TableRow = ({ item }: { item: IUser }) => {
  const { id, firstName, email, roles } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["user", "common"]);

  const [onDelete] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: QUERY_USERS }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{firstName}</th>
        <td>{email}</td>
        <td>{roles.join(" ")}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`/user/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("user:users.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("user:users.modal.title", { firstName: item.firstName })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Users = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { data } = useQuery(QUERY_USERS);

  const users: Array<IUser> = data?.users ?? [];

  const renderUser = useCallback(
    (item: IUser) => <TableRow key={item.id} item={item} />,
    []
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("user:users.nav.users")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary"
        >
          {t("user:users.button.addUser")}
        </Link>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("user:users.th.firstName")}</th>
            <th scope="col">{t("user:users.th.email")}</th>
            <th scope="col">{t("user:users.th.roles")}</th>
            <th scope="col">{t("user:users.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{users.map(renderUser)}</tbody>
      </Table>
    </div>
  );
});

const UsersRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Users />
      </Route>
      <Route exact path={`${path}/new`}>
        <UserCreate />
      </Route>
      <Route path={`${path}/:id`}>
        <UserCreate />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default UsersRoute;
