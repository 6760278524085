import { useEffect, useRef } from "react";

import { useQuery } from "@apollo/client";

import { QUERY_AUTH } from "../../config/graphql/query";

const API_URL = process.env.REACT_APP_API_URL as string;

const [protocol, host] = API_URL.split("//");

const BASE_URL = `${protocol === "https:" ? "wss" : "ws"}://${host}`;

type EventParams = {
  open?: () => void;
  close?: () => void;
  message?: <T>(message: T) => void;
};

const useSocket = (url = "", events?: EventParams) => {
  const {
    // @ts-ignore
    data: { clientId, token },
  } = useQuery(QUERY_AUTH);

  const socket = useRef<WebSocket>(
    new WebSocket([BASE_URL, url].join(""), [token, clientId])
  );

  useEffect(() => {
    // prettier-ignore
    socket.current = new WebSocket([BASE_URL, url].join(""), [token, clientId]);

    socket.current.onmessage = (e) => {
      let message: undefined | { [key: string]: any };

      try {
        message = JSON.parse(e.data);
      } catch {
        message = e.data;
      }

      events?.message?.(message);
    };

    socket.current.onopen = () => {
      events?.open?.();
    };

    socket.current.close = () => {
      events?.close?.();
    };

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, clientId, token]);

  return socket.current;
};

export default useSocket;
