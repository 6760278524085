import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

import "./lib/dayjs";
import "./lib/i18n";

import { ToastContainer } from "react-toastify";

import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/Root";

// CI Trigger: 3

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer position="bottom-right" />
  </React.StrictMode>,
  document.getElementById("root")
);
