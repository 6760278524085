import React, { useCallback, useMemo } from "react";

import Select from "react-select";
import map from "lodash/map";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm, Controller } from "react-hook-form";

import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import {
  QUERY_ENTITIES_PARENTS,
  QUERY_ENTITIES_OPTIONS,
} from "../../../../config/graphql/query";

import { CREATE_ENTITY_PROPERTIES } from "../../../../config/graphql/mutation";
import { Button } from "react-bootstrap";

const CompanyEntriesForm = React.memo(() => {
  const {
    //@ts-ignore
    params: { id },
  } = useRouteMatch();

  const { t } = useTranslation(["company", "common"]);

  const { data: entityParentsData } = useQuery(QUERY_ENTITIES_PARENTS, {
    skip: !id,
    variables: {
      id,
      sort: {
        title: "ASC",
      },
      filter: { properties: id },
    },
  });

  const { data: entitiesData, loading: isLoadingEntityOptions } = useQuery(
    QUERY_ENTITIES_OPTIONS
  );

  const options = useMemo(() => entitiesData?.entities ?? [], [entitiesData]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        entity: yup.object().shape({
          id: yup.string().required(t("company:entities.yup.entity")),
        }),
      }),
    [t]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [onEntityAttachProperties] = useMutation(CREATE_ENTITY_PROPERTIES, {
    refetchQueries: [
      {
        query: QUERY_ENTITIES_PARENTS,
        variables: {
          id,
          filter: { properties: id },
        },
      },
    ],
  });

  const onSubmit = useCallback(
    ({ entity: { id: entityId } }: { entity: IEntity }) => {
      return onEntityAttachProperties({
        variables: {
          input: {
            id: entityId,
            properties: [id],
          },
        },
      })
        .then(() => {
          toast.success(t("company:entities.toast.updated"));
        })
        .then(() => {
          methods.reset({ entity: null });
        })
        .catch(() => {
          toast.error(t("company:entities.toast.error"));
        });
    },
    [id, onEntityAttachProperties, methods.reset, t]
  );
  const getOptionLabel = useCallback((entity) => {
    const { title, parents } = entity;

    const entityTitle = [...map(parents, "title").reverse(), title]
      .filter(Boolean)
      .join(" - ");

    return entityTitle;
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form className="mb-4" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="entity">
              {t("company:entities.form.entity.label")}
            </label>
            <Controller
              control={methods.control}
              name="entity"
              render={({ onChange, ...props }) => (
                <Select
                  aria-describedby="entity-help"
                  getOptionLabel={getOptionLabel}
                  getOptionValue={({ id }) => id}
                  options={options}
                  onChange={(value) => onChange(value || [])}
                  isLoading={isLoadingEntityOptions}
                  {...props}
                />
              )}
            />
            <small id="entity-help" className="form-text text-muted small">
              {t("company:entities.form.entity.entity-help")}
            </small>
          </div>

          <input type="submit" className="btn btn-primary" value="Save" />
        </form>
      </FormProvider>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">{t("company:entities.th.entity")}</th>
            <th scope="col">{t("company:entities.th.type")}</th>
            <th scope="col">{t("company:entities.th.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {entityParentsData?.entities.map((entity: IEntity) => {
            const { title, parents } = entity;

            const entityTitle = [...map(parents, "title").reverse(), title]
              .filter(Boolean)
              .join(" - ");

            const to = [
              "/entity",
              map(entity?.parents, "id").reverse().join("/"),
              `${entity.id}/view`,
            ]
              .filter(Boolean)
              .join("/");

            return (
              <tr key={entity.id}>
                <td>{entityTitle}</td>
                <td>{entity.type}</td>
                <td>
                  <Link to={to}>
                    <Button size="sm" variant="primary">
                      {t("common:view")}
                    </Button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
});

export default CompanyEntriesForm;
