import React, { useMemo, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import map from "lodash/map";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import {
  ArrayField,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "../../../components/Input";

import { QUERY_CLIENT_TOKEN } from "../../../config/graphql/query";
import {
  ADD_CLIENT_TOKEN,
  UPDATE_CLIENT_TOKEN,
  DELETE_CLIENT_TOKEN,
} from "../../../config/graphql/mutation";

type FormVariables = Omit<IClientToken, "ip"> & {
  ip: { value: string }[];
};

const ClientTokenForm = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const [showIp, setShowIp] = useState<number>();

  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const {
    params: { id: tokenId, clientId },
  } = useRouteMatch<{ id: string; clientId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string(),
        development: yup.bool(),
        ip: yup.array(yup.object().shape({ value: yup.string() })),
      }),
    []
  );

  const methods = useForm<FormVariables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      client: clientId,
      title: "",
      ip: [{ value: "" }],
      development: false,
    },
  });

  const addresses = useFieldArray({
    control: methods.control,
    name: "ip",
  });

  const [onCreate] = useMutation(ADD_CLIENT_TOKEN);
  const [onUpdate] = useMutation(UPDATE_CLIENT_TOKEN);
  const [onDelete] = useMutation(DELETE_CLIENT_TOKEN);

  const { data: clientTokenData } = useQuery(QUERY_CLIENT_TOKEN, {
    skip: !tokenId,
    variables: { id: tokenId, client: clientId },
    onCompleted: ({ clientToken: { ip, ...clientToken } }) => {
      methods.reset({
        ...clientToken,
        ip: ip.map((ipCode: string) => ({
          value: ipCode,
          label: ipCode,
        })),
      });
    },
  });

  const onRemoveClientToken = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({
        variables: { input: { id: tokenId, client: clientId } },
      })
        .then(() => {
          history.replace(`/client/${clientId}/token`);
          toast.success(t("client:client.token.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [clientId, history, onDelete, tokenId, t]
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  const onSubmit = ({ ip: addresses, ...rest }: FormVariables) => {
    let input: IClientToken = {
      ...rest,
      ip: map(addresses, ({ value }) => value),
    };

    if (tokenId) {
      input = {
        ...input,
        id: tokenId,
        client: clientId,
      };

      return onUpdate({ variables: { input } })
        .then(() => {
          toast.success(t("client:client.token.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    input = {
      ...input,
      client: clientId,
    };

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addClientToken: { id: tokenId },
          },
        }) => {
          history.replace(`/client/${clientId}/token/${tokenId}`);
          toast.success(t("client:client.token.toast.added"));
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  const renderAddresses = useCallback(
    (
      field: Partial<ArrayField<{ value: string }, "id">>,
      index: number,
      array: Partial<ArrayField<{ value: string }, "id">>[]
    ) => {
      return (
        <div key={field.id}>
          <div className="form-row">
            <div className="col-md-9 mb-3">
              <Input
                ref={methods.register()}
                name={`ip[${index}].value`}
                className="form-control"
                defaultValue={field.value}
              />
            </div>
            <div className="col-md-3 mb-3">
              <button
                disabled={array.length === 1}
                onClick={() => {
                  addresses.remove(index);
                }}
                className="btn btn-danger"
              >
                {t("common:remove")}
              </button>
            </div>
          </div>
          <hr />
        </div>
      );
    },
    [addresses, methods, t]
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={"/client"}>{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/client/${clientId}`}>
              {clientTokenData?.clientToken?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/client/${clientId}/token`}>
              {t("client:client.token.nav.tokens")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {clientTokenData?.clientToken?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="website">
                {t("client:client.token.form.website")}
              </label>
              <div className="col-sm-10">
                {clientTokenData?.clientToken?.token
                  ? clientTokenData?.clientToken?.token
                  : `N/A`}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.token.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                ref={methods.register()}
                name="development"
                className="form-check-input"
                value=""
              />
              <label className="form-check-label ">
                {t("client:client.token.form.development")}
              </label>
            </div>
            <hr />
            <h5>{t("client:client.token.form.ip")}</h5>
            {addresses.fields.map(renderAddresses)}
            <button
              className="btn btn-secondary mb-4"
              onClick={(e) => {
                e.preventDefault();

                addresses.append({ value: "" });
              }}
            >
              {t("client:client.token.button.add")}
            </button>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {tokenId && (
              <button onClick={onBeforeRemove} className="btn btn-danger ml-3">
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("client:client.token.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("client:client.token.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveClientToken}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={typeof showIp === "number"}
        onHide={() => setShowIp(undefined)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("client:client.token.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("client:client.token.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowIp(undefined)}>
            {t("common:cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowIp(undefined);
              addresses.remove(showIp);
            }}
          >
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ClientTokenForm;
