import React, { useMemo, useCallback, useEffect, useRef } from "react";
import * as yup from "yup";

import clsx from "clsx";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";

import {
  Link,
  Route,
  Switch,
  NavLink,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import Input from "../../../components/Input";

import { QUERY_BOARDS, QUERY_BOARD } from "../../../config/graphql/query";

import {
  CREATE_NAMEBOARD,
  UPDATE_NAMEBOARD,
} from "../../../config/graphql/mutation";

import { SCREEN_TEMPLATES } from "../../../config/const/common";

import PathsRouter from "./Paths";
import ItemsRouter from "./Items";
import SettingsRoute from "./Settings";
import ServiceRoute from "./Service";

import { useTranslation } from "react-i18next";

const Information = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const [, copyToClipboard] = useCopyToClipboard();

  const history = useHistory();

  const { t } = useTranslation(["screens", "common"]);

  const boardFrame = useRef(null);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("screens:screen.screenRoute.yup.title")),
        template: yup
          .string()
          .required(t("screens:screen.screenRoute.yup.template")),
      }),
    [t]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: screenData } = useQuery<{ nameboard: INameboard }>(
    QUERY_BOARD,
    {
      skip: !screenId,
      variables: {
        id: screenId,
      },
      onError: () => history.replace("/screen"),
    }
  );

  useEffect(() => {
    methods.reset(screenData?.nameboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData]);

  const [onSave] = useMutation(screenId ? UPDATE_NAMEBOARD : CREATE_NAMEBOARD, {
    refetchQueries: [
      {
        query: QUERY_BOARDS,
      },
    ],
  });

  const onSubmit = useCallback(
    (values) => {
      const input = {
        ...values,
        ...(screenId && { id: screenId }),
      };

      return onSave({ variables: { input } })
        .then(({ data }) => {
          if (data?.addNameboard?.id) {
            history.replace(`/screen/${screenId}`);

            toast.success(t("screens:screen.screenRoute.toast.created"));
            return;
          }

          toast.success(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [history, screenId, onSave, t]
  );

  return (
    <FormProvider {...methods}>
      <div className="tab-pane active" role="tabpanel">
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.screenRoute.form.template")}
              </label>
              <select
                ref={methods.register}
                name="template"
                className="custom-select"
              >
                {SCREEN_TEMPLATES.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-8 row">
            <div className="col-12 mb-2">
              <iframe
                key={methods.getValues("template")}
                ref={boardFrame}
                title={`screen-${screenId}`}
                className="border border-dark"
                src={screenData?.nameboard?.previewUrl}
                style={{
                  width: 600 / (4 / 3),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
            <div className="col-12">
              <a
                href={screenData?.nameboard?.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mr-2"
              >
                {t("screens:screen.screenRoute.form.preview")}
              </a>
              <a
                href={screenData?.nameboard?.playerUrl}
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();

                  copyToClipboard(e.currentTarget.href);

                  toast.success("Link copied");
                }}
              >
                {t("screens:screen.screenRoute.form.copyURL")}
              </a>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </FormProvider>
  );
});

const ScreenRoute = React.memo(() => {
  const { url, path } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery(QUERY_BOARD, {
    skip: !screenId,
    variables: { id: screenId },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/screen"}>
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.nameboard?.title ??
              t("screens:screen.screenRoute.nav.screen")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={url}>
            {t("screens:screen.screenRoute.nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !screenId,
            })}
            to={`${url}/settings`}
          >
            {t("screens:screen.screenRoute.nav.settings")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !screenId,
            })}
            to={`${url}/item`}
          >
            {t("screens:screen.screenRoute.nav.items")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`${url}/path`}>
            {t("screens:screen.screenRoute.nav.paths")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route exact path={path}>
            <Information />
          </Route>
          <Route path={`${path}/item`}>
            <ItemsRouter />
          </Route>
          <Route path={`${path}/settings`}>
            <SettingsRoute />
          </Route>
          <Route path={`${path}/path`}>
            <PathsRouter />
          </Route>
          <Route exact path={`${path}/service`}>
            <ServiceRoute />
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default ScreenRoute;
