import React from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  useRouteMatch,
  useParams,
  NavLink,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { useQuery } from "@apollo/client";

import FileUploadProvider from "../../../components/FileUpload";
import { QUERY_PROPERTY_COMPANY } from "../../../config/graphql/query";

import CompanySocialForm from "./Social";
import CompanyContactForm from "./Contact";
import CompanyInformationForm from "./Information";

import BoardsRoute from "./Boards";
import EntitiesRoute from "./Entities";
import WayfindingsRoute from "./Wayfindings";
import SettingsRoute from "./Settings";

const CompanyCreate = React.memo(() => {
  const match = useRouteMatch();

  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["company"]);

  const { data } = useQuery(QUERY_PROPERTY_COMPANY, {
    skip: !id,
    variables: { id },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/company"}>{t("company:nav.companies")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.property?.title ?? t("company:nav.company")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("company:nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/contact`}
          >
            {t("company:nav.contact")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/social`}
          >
            {t("company:nav.social")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/settings`}
          >
            {t("company:nav.settings")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/entity`}
          >
            {t("company:nav.entities")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/screen`}
          >
            {t("company:nav.screens")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/wayfinding`}
          >
            {t("company:nav.wayfindings")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <FileUploadProvider>
                <CompanyInformationForm />
              </FileUploadProvider>
            </div>
          </Route>
          <Route exact path={`${match.path}/contact`}>
            <div className="tab-pane active" role="tabpanel">
              <CompanyContactForm />
            </div>
          </Route>
          <Route exact path={`${match.path}/social`}>
            <div className="tab-pane active" role="tabpanel">
              <CompanySocialForm />
            </div>
          </Route>
          <Route exact path={`${match.path}/settings`}>
            <div className="tab-pane active" role="tabpanel">
              <SettingsRoute />
            </div>
          </Route>
          <Route exact path={`${match.path}/entity`}>
            <div className="tab-pane active" role="tabpanel">
              <EntitiesRoute />
            </div>
          </Route>
          <Route exact path={`${match.path}/screen`}>
            <div className="tab-pane active" role="tabpanel">
              <BoardsRoute />
            </div>
          </Route>
          <Route exact path={`${match.path}/wayfinding`}>
            <div className="tab-pane active" role="tabpanel">
              <WayfindingsRoute />
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default CompanyCreate;
