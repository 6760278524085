import React, { useRef, useCallback } from "react";

import clsx from "clsx";

import { NavLink } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import useToggle from "react-use/lib/useToggle";
import useClickAway from "react-use/lib/useClickAway";

import { useAuth } from "../context/Auth";
import { useCurrentClient, useClients } from "../context/Client";

import { QUERY_AUTH } from "../config/graphql/query";
import { useTranslation } from "react-i18next";

const ClientSelect = React.memo(() => {
  const client = useApolloClient();

  const clients = useClients();
  const currentClient = useCurrentClient();

  const [visible, toggle] = useToggle(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useClickAway(ref, () => {
    toggle(false);
  });

  const renderClient = useCallback(
    ({ title, id }) => {
      const onChangeClient = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        toggle(false);

        const token = window.sessionStorage.getItem("Authorization");

        window.localStorage.setItem("Client", `${id}`);

        window.location.reload();

        await client.resetStore();

        await client.cache.writeQuery({
          query: QUERY_AUTH,
          data: {
            clientId: id,
            token,
          },
        });
      };

      return (
        <a
          key={id}
          className="dropdown-item"
          href="/asd"
          onClick={onChangeClient}
        >
          {title}
        </a>
      );
    },
    [client]
  );

  return (
    <div
      ref={ref}
      className={clsx("dropdown p-3", {
        show: visible,
      })}
    >
      <button
        className="btn btn-sm btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={visible}
        onClick={toggle}
      >
        {currentClient?.title}
      </button>
      <div
        className={clsx("dropdown-menu", { show: visible })}
        aria-labelledby="dropdownMenuButton"
      >
        {clients.map(renderClient)}
      </div>
    </div>
  );
});
const Sidebar = () => {
  const { t } = useTranslation(["sidebar"]);

  const { logout } = useAuth();

  const onLogout = useCallback((e) => {
    e.preventDefault();

    logout();
  }, []);

  return (
    <div
      className="bg-light border-right d-flex flex-column"
      id="sidebar-wrapper"
    >
      <div className="sidebar-heading">Wayfinding</div>
      <ClientSelect />
      <div className="list-group list-group-flush h-100">
        <NavLink
          to="/entity"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.entity")}
        </NavLink>
        <NavLink
          to="/company"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.company")}
        </NavLink>
        <NavLink
          to="/screen"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.screen")}
        </NavLink>
        <NavLink
          to="/wayfinding"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.wayfinding")}
        </NavLink>
        <NavLink
          to="/client"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.client")}
        </NavLink>
        <NavLink to="/user" className="list-group-item list-group-item-action">
          {t("sidebar:link.user")}
        </NavLink>
        <NavLink
          to="/meetings"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.meetings")}
        </NavLink>
        <NavLink
          to="/visitorLog"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.visitorLog")}
        </NavLink>
        <NavLink to="/log" className="list-group-item list-group-item-action">
          {t("sidebar:link.log")}
        </NavLink>
        <NavLink
          to="/errorLog"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.errorLog")}
        </NavLink>
        <NavLink
          to="/assets"
          className="list-group-item list-group-item-action"
        >
          {t("sidebar:link.assets")}
        </NavLink>
      </div>
      <div className="list-group list-group-flush">
        <a
          href="/login"
          className="list-group-item list-group-item-action"
          onClick={onLogout}
        >
          {t("sidebar:button.logout")}
        </a>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
