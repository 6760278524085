import React from "react";

import clsx from "clsx";
import useLocalStorage from "react-use/lib/useLocalStorage";

import { useQuery } from "@apollo/client";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Route from "../components/Route";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

import { AuthProvider } from "../context/Auth";
import { ApolloProvider } from "../context/Apollo";
import { ClientProvider } from "../context/Client";

import { QUERY_AUTH } from "../config/graphql/query";

import * as Page from "../pages";

const PublicRouter = React.memo(() => (
  <div className="d-flex" id="wrapper">
    <div className="container-fluid">
      <Switch>
        <Route.Public exact path="/login">
          <Page.Login />
        </Route.Public>
      </Switch>
    </div>
  </div>
));

const PrivateRouter = React.memo(() => {
  const [visible] = useLocalStorage("sidebar");

  const {
    //@ts-ignore
    data: { clientId },
  } = useQuery(QUERY_AUTH);

  return (
    <div
      id="wrapper"
      className={clsx("d-flex", {
        toggled: visible,
      })}
    >
      <ClientProvider>
        <Sidebar />
        <div
          id="page-content-wrapper"
          className="d-flex flex-column"
          key={clientId}
        >
          <Navbar />

          <Switch>
            <Route.Private path="/entity">
              <Page.Entities />
            </Route.Private>
            <Route.Private path="/company">
              <Page.Companies />
            </Route.Private>
            <Route.Private path="/client">
              <Page.Clients />
            </Route.Private>
            <Route.Private path="/user">
              <Page.Users />
            </Route.Private>
            <Route.Private path="/screen">
              <Page.Screens />
            </Route.Private>
            <Route.Private path="/wayfinding">
              <Page.Wayfinding />
            </Route.Private>
            <Route.Private path="/errorLog">
              <Page.ErrorsLog />
            </Route.Private>
            <Route.Private path="/visitorLog">
              <Page.VisitorLog />
            </Route.Private>
            <Route.Private path="/meetings">
              <Page.Meetings />
            </Route.Private>
            <Route.Private path="/log">
              <Page.Logs />
            </Route.Private>
            <Route.Private path="/assets">
              <Page.Assets />
            </Route.Private>
            <Redirect to="/entity" />
          </Switch>
        </div>
      </ClientProvider>
    </div>
  );
});

const Root: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <ApolloProvider>
        <AuthProvider>
          <Switch>
            <Route.Public exact path="/login">
              <PublicRouter />
            </Route.Public>
            <Route.Private path="/">
              <PrivateRouter />
            </Route.Private>
          </Switch>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default Root;
