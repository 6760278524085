import React from "react";
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { QUERY_AUTH } from "../config/graphql/query";

const PublicRoute = React.memo(({ children, ...rest }: RouteProps) => {
  const location: {
    state: { from?: { pathname?: string } };
  } = useLocation();

  const pathname = location.state?.from?.pathname ?? "/";

  const { data } = useQuery(QUERY_AUTH);

  return (
    <Route
      {...rest}
      render={() =>
        !data?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
            }}
          />
        )
      }
    />
  );
});

const PrivateRoute = React.memo(({ children, ...rest }: RouteProps) => {
  const { data } = useQuery(QUERY_AUTH);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!data?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

export default {
  Public: PublicRoute,
  Private: PrivateRoute,
};
