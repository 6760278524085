import React, { useCallback } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useParams,
  Link,
} from "react-router-dom";

import { QUERY_CLIENT_WITH_OUTLOOK_SUBSCRIPTION } from "../../../config/graphql/query";
import { DELETE_OUTLOOK_SUBSCRIPTION } from "../../../config/graphql/mutation";

import Pagination from "../../../components/Pagination";
import ClientIntegrationForm from "./ClientIntegrationForm";

const limit = 10;

const TableRow = ({
  item,
  index,
}: {
  item: IClientOutlookSubscription;
  index: number;
}) => {
  const { url } = useRouteMatch();
  const { t } = useTranslation(["client", "common"]);
  const { clientId } = useParams<{ clientId: string }>();
  const { id, subscriptionId, createdAt, expirationDateTime } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_OUTLOOK_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_WITH_OUTLOOK_SUBSCRIPTION,
        variables: {
          id: clientId,
        },
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{subscriptionId}</td>
        <td>{createdAt}</td>
        <td>{expirationDateTime}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.integrations.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.integrations.modal.body", {
            id: id,
            subscriptionId: subscriptionId,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ClientIntegration = React.memo(() => {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery(QUERY_CLIENT_WITH_OUTLOOK_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !clientId,
    variables: {
      id: clientId,
    },
  });

  const outlookSubscriptionsArr = data?.client?.outlookSubscriptions ?? [];
  const outlookSubscriptionsCount = outlookSubscriptionsArr.length ?? 0;

  const renderClientIntegration = useCallback(
    (item: IClientOutlookSubscription, index) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    []
  );

  const onSubmit = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/outlook/auth/login?client=${clientId}`;
    },
    [clientId]
  );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-center my-4">
        <Button onClick={onSubmit}>
          {t("client:client.integrations.button.generate")}
        </Button>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">
                {t("client:client.integrations.th.subscription")}
              </th>
              <th scope="col">{t("client:client.integrations.th.created")}</th>
              <th scope="col">{t("client:client.integrations.th.expires")}</th>
              <th scope="col">{t("client:client.integrations.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{outlookSubscriptionsArr.map(renderClientIntegration)}</tbody>
        </Table>
        <Pagination documentsCount={outlookSubscriptionsCount} limit={limit} />
      </>
    </div>
  );
});

export default ClientIntegration;
