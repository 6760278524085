import React, { useCallback, useEffect } from "react";

import clsx from "clsx";
import useLocalStorage from "react-use/lib/useLocalStorage";

import { useQuery, useApolloClient } from "@apollo/client";

import { currentLocale } from "../context/Apollo/cache";

import { QUERY_LOCALE } from "../config/graphql/query";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { i18n } = useTranslation();
  const client = useApolloClient();

  const { data } = useQuery(QUERY_LOCALE);

  const locale = data?.locale ?? "en";

  /**
   * Change i18n locale whenever reactive variable gets updated
   */
  useEffect(() => {
    // i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const [visible, setVisible] = useLocalStorage("sidebar", true);

  const onToggleSidebar = useCallback(() => {
    setVisible(!visible);

    document.getElementById("wrapper")?.classList.toggle("toggled");
  }, [visible, setVisible]);

  const onChangeLocale = useCallback(
    async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      window.localStorage.setItem("Locale", value);

      currentLocale(value);

      await client.resetStore();
    },
    [client]
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom justify-content-between">
      <button
        className={clsx("btn btn-primary", {
          "fa fa-angle-double-right": visible,
          "fa fa-angle-double-left": !visible,
        })}
        id="menu-toggle"
        onClick={onToggleSidebar}
      />
      <form className="form-inline">
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            className={clsx("btn btn-secondary", { active: locale === "en" })}
          >
            <input
              id="en"
              value="en"
              type="radio"
              name="options"
              onChange={onChangeLocale}
              checked={locale === "en"}
            />
            {`EN`}
          </label>
          <label
            className={clsx("btn btn-secondary", { active: locale === "nl" })}
          >
            <input
              id="nl"
              value="nl"
              type="radio"
              name="options"
              checked={locale === "nl"}
              onChange={onChangeLocale}
            />
            {`NL`}
          </label>
        </div>
      </form>
    </nav>
  );
};

export default Navbar;
