import { gql } from "@apollo/client";

import {
  FRAGMENT_FILE,
  FRAGMENT_ENTITY,
  FRAGMENT_COMPANY,
  FRAGMENT_USER,
  FRAGMENT_PROPERTY,
  FRAGMENT_NAMEBOARD,
  FRAGMENT_NAMEBOARD_SETTINGS,
  FRAGMENT_VISIONECT,
  FRAGMENT_ERROR,
  FRAGMENT_ENTITY_DESK,
  FRAGMENT_VISITOR,
  FRAGMENT_MEETING,
  FRAGMENT_ENTITY_PARENTS,
  FRAGMENT_ENTITY_BUILDING,
  FRAGMENT_CLIENT_TOKEN,
  FRAGMENT_OUTLOOK_SUBSCRIPTION,
  FRAGMENT_LOG,
  FRAGMENT_TWILIO_CLIENT,
  FRAGMENT_CLIENT_SENDGRID,
  FRAGMENT_CLIENT_SENDGRID_TEMPLATE,
} from "../fragment";

export const QUERY_ENTITIES = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_ENTITIES_OPTIONS = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      ...FragmentEntity
      ...FragmentEntityParents
    }
  }
  ${FRAGMENT_ENTITY}
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_ENTITIES_PARENTS = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      id
      title
      type
      properties {
        id
        ... on Company {
          type
          title
        }
      }
      ...FragmentEntityParents
    }
  }
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_ENTITY = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_ENTITY_WITH_DESK = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      id
      ... on Building {
        desk {
          id
          email
        }
      }
    }
  }
`;

export const QUERY_COMPANIES = gql`
  query companies(
    $filter: CompanyFilterType
    $sort: CompanySortType
    $pagination: PaginationType
  ) {
    companies(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentCompany
    }
    companiesCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_COMPANY}
`;

export const QUERY_NAMEBOARD_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      title
      entities {
        __typename
        id
        type
        title
        ... on Space {
          id
          title
        }
      }
    }
  }
`;

export const QUERY_COMPANY_NAMEBOARD = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      nameboards {
        id
        title
        items(filter: { property: $id }) {
          id
          entity {
            id
            title
            type
            ...FragmentEntityParents
          }
        }
      }
    }
  }
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_COMPANY_VISIONECT = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      visionects {
        id
        items(filter: { property: $id }) {
          id
          entity {
            id
            title
            type
          }
        }
      }
    }
  }
`;

export const QUERY_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const QUERY_PROPERTY_COMPANY = gql`
  query property($id: ID!) {
    property(id: $id) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_PROPERTY_COMPANY_INFORMATION = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        title
        postbox
        country
        city
        address
        zipCode
        description
        emailLanguage
        logo {
          ...FragmentFile
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const QUERY_PROPERTY_COMPANY_CONTACT = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        website
        email
        phone
        fax
      }
    }
  }
`;

export const QUERY_PROPERTY_COMPANY_METADATA = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        metadata
      }
    }
  }
`;

export const QUERY_PROPERTY_COMPANY_SOCIAL = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        shortenURL
        facebook
        twitter
        linkedIn
      }
    }
  }
`;

export const QUERY_USER_LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      expiresIn
    }
  }
`;

export const QUERY_AUTH = gql`
  query auth {
    token @client
    clientId @client
  }
`;

export const QUERY_CLIENTS = gql`
  query clients(
    $filter: ClientFilterType
    $sort: ClientSortType
    $pagination: PaginationType
  ) {
    clients(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
      email
      domain
    }
  }
`;

export const QUERY_CURRENT_CLIENT = gql`
  query client($id: ID!) {
    clientId @client @export(as: "id")
    client(id: $id) {
      id
      title
      email
      domain
    }
  }
`;

export const QUERY_CLIENT = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      title
      email
      domain
    }
  }
`;

export const QUERY_CLIENT_WITH_TOKEN = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      tokens {
        ...FragmentClientToken
      }
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_WITH_OUTLOOK_SUBSCRIPTION = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      outlookSubscriptions {
        ...FragmentOutlookSubscription
      }
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const QUERY_CLIENT_TOKEN = gql`
  query clientToken($id: ID!, $client: String!) {
    clientToken(id: $id, client: $client) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_TOKENS = gql`
  query clientTokens($filter: ClientTokenFilterType) {
    clientTokens(filter: $filter) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_SENDGRID_LIST = gql`
  query sendGridClients($filter: SendGridClientFilterInput!) {
    sendGridClients(filter: $filter) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const QUERY_CLIENT_SENDGRID = gql`
  query sendGridClient($id: ID!, $client: ID!) {
    sendGridClient(id: $id, client: $client) {
      ...FragmentClientSendGrid
	  client {
		  id
		  title
	  }
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const QUERY_CLIENT_SENDGRID_TEMPLATES = gql`
  query emailTemplates($filter: EmailTemplateFilterInput!) {
    emailTemplates(filter: $filter) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const QUERY_CLIENT_SENDGRID_TEMPLATE = gql`
  query emailTemplate($id: ID!, $client: ID!) {
    emailTemplate(id: $id, client: $client) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const QUERY_USERS = gql`
  query users($filter: UserFilter) {
    users(filter: $filter) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_BOARDS = gql`
  query nameboards(
    $filter: NameboardFilterType
    $sort: NameboardSortType
    $pagination: PaginationType
  ) {
    nameboards(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
      template
      playerUrl
      previewUrl
      client {
        id
      }
    }
  }
`;

export const QUERY_CHILDREN_PROPERTIES = gql`
  query entityChildrenProperties($id: ID!) {
    entityChildrenProperties(id: $id) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_ENTITY_PROPERTIES = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      id
      type
      properties {
        ...FragmentProperty
      }
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_PROPERTIES = gql`
  query properties($filter: PropertyFilter) {
    properties(filter: $filter) {
      id
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_VISIONECTS = gql`
  query visionects(
    $filter: VisionectFilterType
    $sort: VisionectSortType
    $pagination: PaginationType
  ) {
    visionects(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const QUERY_BOARD = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      ...FragmentNameboard
      playerUrl
      previewUrl
      client {
        id
      }
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const QUERY_NAMEBOARD_ITEM = gql`
  query nameboardItem($id: ID!) {
    nameboardItem(id: $id) {
      id
      sort
      plan {
        id
        path
		absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_ITEMS = gql`
  query nameboardItems($filter: NameboardItemsFilter) {
    nameboardItems(filter: $filter) {
      id
      sort
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_PATH = gql`
  query nameboardPath($id: ID!) {
    nameboardPath(id: $id) {
      id
      plan {
        id
        path
		absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_PATHS = gql`
  query nameboardPaths($filter: NameboardPathFilter) {
    nameboardPaths(filter: $filter) {
      id
      plan {
        id
        path
		absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_VISIONECT = gql`
  query visionect($id: ID!) {
    visionect(id: $id) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const QUERY_VISIONECT_DEVICES = gql`
  query visionectDevices {
    visionectDevices {
      Uuid
      SessionId
      Options {
        Name
      }
    }
  }
`;

export const QUERY_VISIONECT_ITEM = gql`
  query visionectItem($id: ID!) {
    visionectItem(id: $id) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_VISIONECT_ITEMS = gql`
  query visionectItems($filter: VisionectItemsFilter) {
    visionectItems(filter: $filter) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_LOCALE = gql`
  query locale {
    locale @client
  }
`;

export const QUERY_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_ERRORS_LOG = gql`
  query errorsLog(
    $filter: ErrorLogFilterType
    $sort: ErrorLogSortType
    $pagination: PaginationType
  ) {
    errorsLog(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentError
    }
    errorsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_ERROR}
`;

export const QUERY_ERROR_LOG = gql`
  query errorLog($id: ID!) {
    errorLog(id: $id) {
      ...FragmentError
    }
  }
  ${FRAGMENT_ERROR}
`;

export const QUERY_ENTITY_DESK = gql`
  query entityDesk($id: ID!) {
    entityDesk(id: $id) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const QUERY_ENTITY_DESKS = gql`
  query entityDesks($filter: EntityDeskFilterType) {
    entityDesks(filter: $filter) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const QUERY_VISITORS = gql`
  query visitors(
    $filter: VisitorFilterType
    $sort: VisitorSortType
    $pagination: PaginationType
  ) {
    visitors(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentVisitor
    }
    visitorsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_VISITOR}
`;

export const QUERY_VISITOR = gql`
  query visitor($id: ID!) {
    visitor(id: $id) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const QUERY_MEETINGS = gql`
  query meetings(
    $filter: MeetingFilterType
    $sort: MeetingSortType
    $pagination: PaginationType
  ) {
    meetings(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentMeeting
    }
    meetingsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_MEETING}
`;

export const QUERY_MEETING = gql`
  query meeting($id: ID!) {
    meeting(id: $id) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const QUERY_BUILDINGS = gql`
  query buildings($filter: BuildingFilter) {
    buildings(filter: $filter) {
      ...FragmentEntityBuilding
    }
  }
  ${FRAGMENT_ENTITY_BUILDING}
`;

export const QUERY_BUILDING = gql`
  query building($id: ID!) {
    building(id: $id) {
      ...FragmentEntityBuilding
    }
  }
  ${FRAGMENT_ENTITY_BUILDING}
`;

export const QUERY_OUTLOOK_SUBSCRIPTION = gql`
  query outlookSubscription($id: ID!) {
    outlookSubscription(id: $id) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const QUERY_OUTLOOK_SUBSCRIPTIONS = gql`
  query outlookSubscriptions(
    $filter: OutlookSubscriptionFilterType
    $sort: OutlookSubscriptionSortType
    $pagination: PaginationType
  ) {
    outlookSubscriptions(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      ...FragmentOutlookSubscription
    }
    outlookSubscriptionsCount(
      filter: $filter
      sort: $sort
      pagination: $pagination
    )
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;
export const QUERY_LOGS = gql`
  query logs(
    $filter: LogFilterType
    $sort: LogSortType
    $pagination: PaginationType
  ) {
    logs(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentLog
    }
    logsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_LOG}
`;

export const QUERY_LOG = gql`
  query log($id: ID!) {
    log(id: $id) {
      ...FragmentLog
    }
  }
  ${FRAGMENT_LOG}
`;

export const QUERY_NAMEBOARD_SETTINGS = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      id
      settings {
        ...FragmentNameboardSettings
      }
    }
  }
  ${FRAGMENT_NAMEBOARD_SETTINGS}
`;

export const QUERY_FILES = gql`
  query files(
    $filter: FileFilterType
    $sort: FileSortType
    $pagination: PaginationType
  ) {
    files(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      createdAt
      updatedAt
      filename
      path
      absolutePath
      md5
    }
    filesCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
`;

export const QUERY_CLIENT_WITH_TWILIO_CLIENTS = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      twilioClients {
        ...FragmentTwilioClient
      }
    }
  }
  ${FRAGMENT_TWILIO_CLIENT}
`;

export const QUERY_CLIENT_TWILIO_CLIENTS = gql`
  query twilioClients {
    twilioClients {
      ...FragmentTwilioClient
    }
  }
  ${FRAGMENT_TWILIO_CLIENT}
`;

export const QUERY_CLIENT_TWILIO_CLIENT = gql`
  query twilioClient($id: ID!) {
    twilioClient(id: $id) {
      ...FragmentTwilioClient
    }
  }
  ${FRAGMENT_TWILIO_CLIENT}
`;
