import React, { useMemo, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";

import clsx from "clsx";

import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";

import { useRouteMatch, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  UploadInput,
  UploadPreview,
  useFiles,
} from "../../../../components/FileUpload";

import {
  QUERY_NAMEBOARD_PATH,
  QUERY_NAMEBOARD_PATHS,
  QUERY_ENTITIES_PARENTS,
} from "../../../../config/graphql/query";

import {
  CREATE_NAMEBOARD_PATH,
  UPDATE_NAMEBOARD_PATH,
  DELETE_NAMEBOARD_PATH,
} from "../../../../config/graphql/mutation";

const PathRoute = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const {
    params: { screenId: nameboard, id },
  }: { params: { screenId: string; id?: string } } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { replace } = useHistory();
  const graphql = useApolloClient();

  const [onSave, { loading: saving }] = useMutation(
    id ? UPDATE_NAMEBOARD_PATH : CREATE_NAMEBOARD_PATH,
    {
      refetchQueries: [
        {
          query: QUERY_NAMEBOARD_PATHS,
          variables: {
            filter: {
              nameboard,
            },
          },
        },
      ],
    }
  );

  const [files, { onUpload: onUploadPathImage }] = useFiles("plan");

  const schema = useMemo(
    () =>
      yup.object().shape({
        entity: yup.object().shape({
          id: yup.string().required(t("screens:screen.paths.path.yup.entity")),
          title: yup.string().optional(),
        }),
      }),
    [t]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [onDeletePath] = useMutation(DELETE_NAMEBOARD_PATH, {
    refetchQueries: [
      {
        query: QUERY_NAMEBOARD_PATHS,
        variables: {
          filter: {
            nameboard,
          },
        },
      },
    ],
  });

  const { data: nameboardPathData, loading } = useQuery(QUERY_NAMEBOARD_PATH, {
    skip: !id,
    variables: { id },
  });

  useEffect(() => {
    methods.reset(nameboardPathData?.nameboardPath ?? {});
  }, [nameboardPathData]);

  const onSubmit = useCallback(
    async ({ entity, ...rest }) => {
      let input = {
        ...rest,
        entity: entity.id,
        ...(!!id ? { id } : { nameboard }),
      };

      if (Array.isArray(files) && files.length > 0) {
        const uploadedFiles = await onUploadPathImage();
        if (uploadedFiles && uploadedFiles[0]) {
          input = {
            ...input,
            plan: uploadedFiles[0].file.id,
          };
        }
      }

      if (id) {
        return onSave({ variables: { input } })
          .then(() => {
            toast.success(t("screens:screen.paths.path.toast.updated"));
          })
          .catch((error) => {
            toast.error(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message
            );
          });
      }

      await onSave({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          if (data?.addNameboardPath?.id) {
            replace(`/screen/${nameboard}/path/${data?.addNameboardPath?.id}`);
            toast.success(t("screens:screen.paths.path.toast.created"));
            return;
          }
          toast.success(t("screens:screen.paths.path.toast.updated"));
        })
        .catch((err) => {
          toast.error(
            err?.networkError?.result?.errors?.[0]?.message ?? err?.message
          );
        });
    },
    [id, files, nameboard, onUploadPathImage, replace, onSave, t]
  );

  const getOptionLabel = useCallback(({ title }: TEntity) => {
    return title;
  }, []);

  const onLoadOptions = useCallback(
    (value) => {
      return graphql
        .query({ query: QUERY_ENTITIES_PARENTS, fetchPolicy: "network-only" })
        .then(({ data }) => data?.entities ?? [])
        .catch((err) => {
          return [];
        });
    },
    [graphql]
  );

  const onRemovePath = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDeletePath({ variables: { id } })
        .then(() => {
          toast.success(t("screens:screen.paths.path.toast.deleted"));
          replace(`/screen/${nameboard}/path`);
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [onDeletePath, id, nameboard, replace, t]
  );

  const onBeforeRemovePath = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  return (
    <>
      <FormProvider {...methods}>
        <div className="tab-pane active" role="tabpanel">
          <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="entity">
                  {t("screens:screen.paths.path.form.entity")}
                </label>
                <Controller
                  control={methods.control}
                  name="entity"
                  render={(props) => (
                    <AsyncSelect
                      defaultOptions
                      className={clsx({
                        "is-invalid": !!methods.errors["entity"],
                      })}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={({ id }) => id}
                      loadOptions={onLoadOptions}
                      {...props}
                    />
                  )}
                />
                {!!methods.errors["entity"] && (
                  <div className="invalid-feedback">
                    {methods.errors["entity"].message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="plan">
                  {t("screens:screen.paths.path.form.plan")}
                </label>
                <UploadInput name="plan" />
                <UploadPreview
                  name="plan"
                  placeholder={nameboardPathData?.nameboardPath?.plan?.path}
                />
              </div>
            </div>

            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={saving || loading}
              />
              {id && (
                <button
                  onClick={onBeforeRemovePath}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("screens:screen.paths.path.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("screens:screen.paths.path.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemovePath}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default PathRoute;
