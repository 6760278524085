export enum Direction {
  LEFT = "LEFT",
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  RIGHT = "RIGHT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export const Directions: Direction[] = [
  Direction.LEFT,
  Direction.TOP_LEFT,
  Direction.BOTTOM_LEFT,
  Direction.RIGHT,
  Direction.TOP_RIGHT,
  Direction.BOTTOM_RIGHT,
  Direction.TOP,
  Direction.BOTTOM,
];

export enum SCREEN_TEMPLATE {
  SECOYA = "secoya",
  JUPTER = "jupiter",
  COLUMBUS = "columbus",
  THE_BASE = "the_base",
  GEBOUW_144 = "gebouw_144",
  TORO = "toro",
}

export const SCREEN_TEMPLATES = [
  SCREEN_TEMPLATE.SECOYA,
  SCREEN_TEMPLATE.JUPTER,
  SCREEN_TEMPLATE.THE_BASE,
  SCREEN_TEMPLATE.GEBOUW_144,
  SCREEN_TEMPLATE.COLUMBUS,
  SCREEN_TEMPLATE.TORO,
];

export const EMAIL_TEMPLATES = [
  "HostMeetingInvitation",
  "HostBadgeMissing",
  "HostVisitorArrived",
  "VisitorMeetingInvitation",
  "VisitorMeetingReminder",
];
