import React, { useCallback, useContext, useState } from "react";

import clsx from "clsx";

import { useDropzone, DropzoneOptions } from "react-dropzone";
import useSocket from "../lib/hooks/useSocket";

type UploadFile = {
  file: File;
  data: string;
};

type UploadedFile = {
  error: boolean;
  file: {
    filename: string;
    id: string;
    md5: string;
    path: string;
  };
  message: string;
};

const onFileRead = (file: File): Promise<UploadFile> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      if (!e.target?.result) {
        return reject(new Error("Failed to read the file."));
      }

      return resolve({
        file,
        data: e.target.result as string,
      });
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const UploadContext = React.createContext<{
  results: { [key: string]: Array<UploadFile> };
  onChange: (files: { [key: string]: Array<UploadFile> }) => void;
}>({
  results: {},
  onChange: () => {},
});

const { Provider } = UploadContext;

export const useUploadProvider = () => useContext(UploadContext);

export const useFiles = (
  name: string
): [
  undefined | Array<UploadFile>,
  { onUpload: () => Promise<Array<UploadedFile>> }
] => {
  const socket = useSocket("/upload");

  const { results } = useUploadProvider();

  const files = results[name];

  const onUpload = useCallback((): Promise<Array<UploadedFile>> => {
    return new Promise((resolve, reject) => {
      socket.send(
        JSON.stringify({
          filename: files[0].file.name,
          file: files[0].data,
        })
      );

      socket.onerror = reject;
      socket.onmessage = (e) => {
        let message:
          | undefined
          | {
              error: boolean;
              file: {
                filename: string;
                id: string;
                md5: string;
                path: string;
              };
              message: string;
            };

        try {
          message = JSON.parse(e.data);
        } catch {
          message = e.data;
        }

        if (!message) {
          return reject(new Error("Failed to upload the file"));
        }

        return resolve([message]);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return [files, { onUpload }];
};

const UploadProvider: React.FC = ({ children }) => {
  const [results, setResults] = useState<{ [key: string]: Array<UploadFile> }>(
    {}
  );

  const onChange = useCallback(
    (nextResults: { [key: string]: Array<UploadFile> }) => {
      setResults((prev) => ({
        ...prev,
        ...nextResults,
      }));
    },
    []
  );

  return <Provider value={{ results, onChange }}>{children}</Provider>;
};

export const UploadInput: React.FC<{
  name: string;
  options?: DropzoneOptions;
}> = ({ options, name, children }) => {
  const { onChange } = useUploadProvider();

  const onDrop = useCallback(
    async (files: File[]) => {
      const results = await Promise.all(files.map(onFileRead));

      onChange({
        [name]: results,
      });
    },
    [name, onChange]
  );

  const state = useDropzone({ ...options, onDrop });

  /**
   * To override use render props pattern
   */

  if (typeof children === "function") {
    return children({ ...state, onDrop });
  }

  return (
    <div
      className={clsx("bg-light border rounded py-4  position-relative", {
        "border-dark": !state.isDragActive,
        "border-primary": state.isDragActive,
      })}
      {...state.getRootProps()}
    >
      <input {...state.getInputProps()} name={name} />
      <div className="d-flex flex-column align-items-center">
        <i className="fa fa-upload text-muted h2" />
        Drag &amp; Drop here or click
      </div>
    </div>
  );
};

export const UploadPreview: React.FC<{
  name: string;
  placeholder?: string;
}> = ({ name, placeholder }) => {
  const { results } = useUploadProvider();

  if (
    !((Array.isArray(results[name]) && results[name].length > 0) || placeholder)
  ) {
    return null;
  }

  return (
    <div
      className="background-image bg-16-9 border border-dark"
      style={{
        backgroundImage: `url("${results[name]?.[0]?.data || placeholder}")`,
      }}
    />
  );
};

UploadInput.defaultProps = {
  name: "",
  options: {
    accept: ["image/*"],
    multiple: false,
  },
};

export default UploadProvider;
