import React, { useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import useToggle from "react-use/lib/useToggle";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useParams } from "react-router-dom";

import { QUERY_CLIENT_WITH_TOKEN } from "../../../config/graphql/query";
import { DELETE_CLIENT_TOKEN } from "../../../config/graphql/mutation";
import Pagination from "../../../components/Pagination";

const limit = 10;

const TableRow = ({ item, index }: { item: IClientToken; index: number }) => {
  const { url } = useRouteMatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, development } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_CLIENT_TOKEN, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_WITH_TOKEN,
        variables: {
          id: clientId,
        },
      },
    ],
    variables: { input: { id, client: clientId } },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{title}</td>
        <td>{development ? "yes" : "no"}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("client:client.tokens.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.tokens.modal.body", { id: id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ClientTokens = React.memo(() => {
  const { url } = useRouteMatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery(QUERY_CLIENT_WITH_TOKEN, {
    fetchPolicy: "network-only",
    skip: !clientId,
    variables: {
      id: clientId,
    },
  });

  const clientTokensArr = data?.client?.tokens ?? [];
  const clientTokensCount = clientTokensArr.length ?? 0;

  const renderClientToken = useCallback(
    (item: IClientToken, index) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    []
  );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("client:client.tokens.button.add")}
          </Link>
        </div>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("client:client.tokens.th.title")}</th>
              <th scope="col">{t("client:client.tokens.th.development")}</th>
              <th scope="col">{t("client:client.tokens.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{clientTokensArr.map(renderClientToken)}</tbody>
        </Table>
        <Pagination documentsCount={clientTokensCount} limit={limit} />
      </>
    </div>
  );
});

export default ClientTokens;
