import React, { useMemo, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";

import { pickBy } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "../../../components/Input";

import { useTranslation } from "react-i18next";

import { PropertyType } from "../../../config/const/property";
import {
  QUERY_PROPERTY_COMPANY_SOCIAL,
  QUERY_COMPANIES,
} from "../../../config/graphql/query";
import {
  UPDATE_PROPERTY_COMPANY,
  DELETE_COMPANY,
} from "../../../config/graphql/mutation";

const CompanySocialForm = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const history = useHistory();

  const { t } = useTranslation(["company", "common"]);

  const {
    //@ts-ignore
    params: { id },
  } = useRouteMatch();

  const schema = useMemo(
    () =>
      yup.object().shape({
        shortenURL: yup.string().url(t("company:social.yup.shortenURL")),
        facebook: yup
          .string()
          .matches(/^(http|https)\:\/\/www.facebook.com\/.*/i, {
            message: t("company:social.yup.shortenURL"),
            excludeEmptyString: true,
          }),
        twitter: yup
          .string()
          .matches(/^(http|https)\:\/\/(www.|)twitter.com\/.*/i, {
            message: t("company:social.yup.shortenURL"),
            excludeEmptyString: true,
          }),
        linkedIn: yup
          .string()
          .matches(/^(http|https)\:\/\/(www.|)linkedin.com\/.*/i, {
            message: t("company:social.yup.shortenURL"),
            excludeEmptyString: true,
          }),
      }),
    [t]
  );

  const [onUpdate] = useMutation(UPDATE_PROPERTY_COMPANY);
  const [onDelete] = useMutation(DELETE_COMPANY);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: propertyData } = useQuery(QUERY_PROPERTY_COMPANY_SOCIAL, {
    skip: !id,
    variables: { id },
  });

  useEffect(() => {
    methods.reset(propertyData?.property);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData]);

  const onRemoveCompany = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      history.replace(`/company`);
      return onDelete({ variables: { id } })
        .then(() => {
          toast.success(t("company:social.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [history, onDelete, id, t]
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow, onRemoveCompany]
  );

  const onSubmit = (values: ICompany) => {
    const input = {
      //@ts-ignore
      ...pickBy(values),
      id: id,
      type: PropertyType.Company,
    };

    return onUpdate({ variables: { input } })
      .then(() => {
        toast.success(t("company:social.toast.updated"));
      })
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="facebook">
                {t("company:social.form.facebook")}
              </label>
              <Input name="facebook" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="twitter">
                {t("company:social.form.twitter")}
              </label>
              <Input name="twitter" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="linkedIn">
                {t("company:social.form.linkedIn")}
              </label>
              <Input name="linkedIn" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {id && (
              <button onClick={onBeforeRemove} className="btn btn-danger ml-3">
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("company:social.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("company:social.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveCompany}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default CompanySocialForm;
