import React, { useMemo, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import map from "lodash/map";
import get from "lodash/get";
import AsyncSelect from "react-select/async";

import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  UploadInput,
  UploadPreview,
  useFiles,
} from "../../../../components/FileUpload";

import {
  QUERY_NAMEBOARD_ITEM,
  QUERY_NAMEBOARD_ITEMS,
  QUERY_ENTITIES_PARENTS,
} from "../../../../config/graphql/query";

import {
  CREATE_NAMEBOARD_ITEM,
  UPDATE_NAMEBOARD_ITEM,
  DELETE_NAMEBOARD_ITEM,
} from "../../../../config/graphql/mutation";

import { EntityType } from "../../../../config/const/entity";
import Input from "../../../../components/Input";

const ItemRoute = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["screens", "common"]);

  const {
    params: { screenId: nameboard, id },
  }: { params: { screenId: string; id?: string } } = useRouteMatch();

  const { replace } = useHistory();
  const graphql = useApolloClient();

  const [onSave, { loading: saving }] = useMutation(
    id ? UPDATE_NAMEBOARD_ITEM : CREATE_NAMEBOARD_ITEM,
    {
      refetchQueries: [
        {
          query: QUERY_NAMEBOARD_ITEMS,
          variables: {
            filter: {
              nameboard,
            },
          },
        },
      ],
    }
  );

  const [files, { onUpload: onUploadPathImage }] = useFiles("plan");

  const schema = useMemo(
    () =>
      yup.object().shape({
        entity: yup.object().shape({
          id: yup.string().required(t("screens:screen.items.item.yup.entity")),
          title: yup.string(),
        }),
      }),
    [t]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: nameboardItemData, loading } = useQuery(QUERY_NAMEBOARD_ITEM, {
    skip: !id,
    variables: { id },
  });

  useEffect(() => {
    methods.reset(nameboardItemData?.nameboardItem ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameboardItemData]);

  const [onDeleteItem] = useMutation(DELETE_NAMEBOARD_ITEM, {
    refetchQueries: [
      {
        query: QUERY_NAMEBOARD_ITEMS,
        variables: {
          filter: {
            nameboard,
          },
        },
      },
    ],
  });

  const onRemoveItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDeleteItem({ variables: { id } })
        .then(() => {
          toast.success(t("screens:screen.items.item.toast.deleted"));
          replace(`/screen/${nameboard}/item`);
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [onDeleteItem, replace, nameboard, id, t]
  );

  const onBeforeRemoveItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  const onSubmit = useCallback(
    async ({ entity, sort, ...rest }) => {
      let input = {
        ...rest,
        sort: parseInt(sort),
        nameboard,
        entity: entity.id,
        ...(!!id && { id }),
      };

      if (Array.isArray(files) && files.length > 0) {
        const uploadedFiles = await onUploadPathImage();
        if (uploadedFiles && uploadedFiles[0]) {
          input = { ...input, plan: uploadedFiles[0].file.id };
        }
      }

      if (id) {
        return onSave({ variables: { input } })
          .then(() => {
            toast.success(t("screens:screen.items.item.toast.updated"));
          })
          .catch((error) => {
            toast.error(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message
            );
          });
      }

      await onSave({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          if (data?.addNameboardItem?.id) {
            replace(`/screen/${nameboard}/item/${data?.addNameboardItem?.id}`);

            toast.success(t("screens:screen.items.item.toast.created"));
            return;
          }

          toast.success(t("screens:screen.items.item.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [id, files, nameboard, onUploadPathImage, replace, onSave, t]
  );

  const getOptionLabel = useCallback(
    ({ title, properties, parents }: TEntity) => {
      const propertiesString =
        map(properties, "title").filter(Boolean).join(", ") || "N/A";

      const entityTitle = [...map(parents, "title").reverse(), title]
        .filter(Boolean)
        .join(" - ");

      return [propertiesString, `(${entityTitle})`].filter(Boolean).join(" - ");
    },
    []
  );

  const onLoadOptions = useCallback(
    (value) => {
      return graphql
        .query({
          query: QUERY_ENTITIES_PARENTS,
          variables: {
            filter: {
              title: value,
              type: EntityType.Space,
            },
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => data?.entities ?? [])
        .catch((error) => {
          return [];
        });
    },
    [graphql]
  );

  return (
    <>
      <FormProvider {...methods}>
        <div className="tab-pane active" role="tabpanel">
          <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="entity">
                  {t("screens:screen.items.item.form.entity")}
                </label>
                <Controller
                  control={methods.control}
                  name="entity"
                  render={(props) => (
                    <AsyncSelect
                      defaultOptions
                      className={clsx({
                        "is-invalid": !!get(methods, "errors.entity.id"),
                      })}
                      // closeMenuOnSelect={false}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={({ id }) => id}
                      loadOptions={onLoadOptions}
                      {...props}
                    />
                  )}
                />
                {!!get(methods, "errors.entity.id") && (
                  <div className="invalid-feedback">
                    {get(methods, "errors.entity.id").message}
                  </div>
                )}
                <small id="emailHelp" className="form-text text-muted">
                  {t("screens:screen.items.item.form.emailHelp")}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="sort">
                  {t("screens:screen.items.item.form.sort")}
                </label>
                <Input name="sort" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="plan">
                  {t("screens:screen.items.item.form.plan")}
                </label>
                <UploadInput name="plan" />
                <UploadPreview
                  name="plan"
                  placeholder={nameboardItemData?.nameboardItem?.plan?.absolutePath}
                />
              </div>
              {/* <div className="form-group">
              <label htmlFor="type">Direction</label>
              <select
                ref={methods.register}
                name="direction"
                className="custom-select"
              >
                {Directions.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="distance">Distance</label>
              <Input name="distance" className="form-control" />
            </div> */}
            </div>
            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={saving || loading}
              />
              {id && (
                <button
                  onClick={onBeforeRemoveItem}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("screens:screen.items.item.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("screens:screen.items.item.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveItem}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ItemRoute;
