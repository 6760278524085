import React from "react";

import { useQuery } from "@apollo/client";
import {
  Switch,
  Route,
  useRouteMatch,
  NavLink,
  Redirect,
} from "react-router-dom";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { QUERY_ENTITY } from "../../../config/graphql/query";

import EntityRoute from "./Entity";
import EntityPropertyForm from "./Property";
import EntityFloorplanRoute from "./Floorplan";
import EntityDesk from "./EntityDesk";
import FileUploadProvider from "../../../components/FileUpload";

import { EntityType } from "../../../config/const/entity";

const Entity = React.memo(({ id }: { id?: number }) => {
  const match = useRouteMatch();
  const { t } = useTranslation(["entities", "common"]);

  const { data, loading } = useQuery(QUERY_ENTITY, {
    skip: !id,
    // onError: console.log,
    variables: {
      id,
    },
  });

  const entity = data?.entity;

  return (
    <>
      <div className="col">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link"
              to={{
                pathname: match.url,
              }}
            >
              {t("entities:floorPlan.entity.nav.information")}
            </NavLink>
          </li>
          <li className={clsx("nav-item")}>
            <NavLink
              exact
              className={clsx("nav-link", { disabled: !id })}
              to={{
                pathname: `${match.url}/property`,
              }}
            >
              {t("entities:floorPlan.entity.nav.properties")}
            </NavLink>
          </li>
          {entity?.type === EntityType.Building && (
            <li className={clsx("nav-item")}>
              <NavLink
                exact
                className={clsx("nav-link", { disabled: !id })}
                to={{
                  pathname: `${match.url}/desk`,
                }}
              >
                {t("entities:floorPlan.entity.nav.desk")}
              </NavLink>
            </li>
          )}
          {entity?.type === EntityType.Floor && (
            <li className={clsx("nav-item")}>
              <NavLink
                exact
                className={clsx("nav-link", { disabled: !id })}
                to={{
                  pathname: `${match.url}/floorplan`,
                }}
              >
                {t("entities:floorPlan.entity.nav.floorPlan")}
              </NavLink>
            </li>
          )}
        </ul>
        {!loading && (
          <div className="tab-content">
            <div className="tab-pane active" role="tabpanel">
              <Switch>
                <Route exact path={match.path}>
                  <FileUploadProvider>
                    <EntityRoute />
                  </FileUploadProvider>
                </Route>
                {id && entity?.type === EntityType.Building && (
                  <Route exact path={`${match.path}/desk`}>
                    <EntityDesk />
                  </Route>
                )}
                {id && entity?.type === EntityType.Floor && (
                  <Route exact path={`${match.path}/floorplan`}>
                    <EntityFloorplanRoute />
                  </Route>
                )}
                {id && (
                  <Route exact path={`${match.path}/property`}>
                    <EntityPropertyForm />
                  </Route>
                )}
                <Redirect to={match.path} />
              </Switch>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default Entity;
