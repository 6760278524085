import React from "react";

const SettingsRoute = () => {
  return (
    <>
      <form></form>
    </>
  );
};

export default SettingsRoute;
