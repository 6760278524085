import React, { useContext } from "react";

import { useQuery } from "@apollo/client";

/* query */
import { QUERY_ENTITY } from "../config/graphql/query";

type TEntityContext = {
  entity: Partial<TEntity>;
  parent?: Partial<TEntity>;
};

const EntityContext = React.createContext<TEntityContext>({
  entity: {},
  parent: undefined,
});

const { Consumer, Provider } = EntityContext;

type ProviderProps = {
  children: React.ReactNode;
  value: {
    id?: string;
  };
};

export const useEntity = (): Partial<TEntity> => {
  const { entity } = useContext(EntityContext);

  return entity;
};

export const useEntityParent = (): Partial<TEntity> | undefined => {
  const { parent } = useContext(EntityContext);

  return parent;
};

export const EntityProvider = React.memo(
  ({ children, value: { id } }: ProviderProps) => {
    const { data, loading } = useQuery(QUERY_ENTITY, {
      skip: !id,
      variables: {
        id,
      },
    });

    const entity = data?.entity ?? { id };

    const parent = useEntity();

    return (
      <Provider value={{ entity, parent }}>
        {loading ? null : children}
      </Provider>
    );
  }
);

export { Consumer as EntityConsumer };

export default EntityContext;
