import React, { useCallback } from "react";

import { useQuery, useMutation } from "@apollo/client";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { QUERY_VISIONECTS } from "../../config/graphql/query";
import { DELETE_VISIONECT } from "../../config/graphql/mutation";
import { Route, Switch, useRouteMatch, Redirect, Link } from "react-router-dom";

import Wayfinding from "./Wayfinding";

const TableRow = ({ item }: { item: IVisionect }) => {
  const { id, title, template, device } = item;
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["wayfinding", "common"]);

  const [onDelete] = useMutation(DELETE_VISIONECT, {
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {id}
        </th>
        <td>{title}</td>
        <td>{template}</td>
        <td>{device?.Options?.Name || device?.Uuid}</td>
        <td>{device?.State}</td>
        <td className="text-nowrap">
          <Button
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={() => setVisible(true)}
          >
            {t("common:delete")}
          </Button>
          <Link to={`/wayfinding/${id}`}>
            <Button
              size="sm"
              variant="primary"
              onClick={() => setVisible(false)}
            >
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("wayfinding:wayfindings.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("wayfinding:wayfindings.modal.body", "title: item.title")}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Wayfindings = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["wayfinding", "common"]);

  const { data } = useQuery(QUERY_VISIONECTS, {
    variables: { sort: { title: "ASC" } },
  });

  const visionects: Array<IVisionect> = data?.visionects ?? [];

  const renderWayfinding = useCallback(
    (item: IVisionect) => <TableRow key={item.id} item={item} />,
    []
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("wayfinding:wayfindings.nav.wayfinding")}
          </li>
        </ol>
      </nav>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary"
        >
          {t("wayfinding:wayfindings.button.add")}
        </Link>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("wayfinding:wayfindings.th.title")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.template")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.device")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.status")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{visionects.map(renderWayfinding)}</tbody>
      </Table>
    </div>
  );
});

const WayfindingsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Wayfindings />
      </Route>
      <Route exact path={`${path}/new`}>
        <Wayfinding />
      </Route>
      <Route path={`${path}/:wayfindingId`}>
        <Wayfinding />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default WayfindingsRouter;
