import React, { useMemo } from "react";

import * as yup from "yup";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import Select from "react-select";

import map from "lodash/map";

import { toast } from "react-toastify";
import { useRouteMatch } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";

import { useEntityParent } from "../../../context/Entity";
import {
  QUERY_PROPERTIES,
  QUERY_ENTITY_PROPERTIES,
  QUERY_ENTITIES,
} from "../../../config/graphql/query";
import { CREATE_ENTITY_PROPERTIES } from "../../../config/graphql/mutation";

const PropertyRoute = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation(["entities", "common"]);

  const parent = useEntityParent();

  const schema = useMemo(
    () =>
      yup.object().shape({
        properties: yup.array().of(
          yup.object().shape({
            id: yup.string(),
          })
        ),
      }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: propertiesData } = useQuery(QUERY_PROPERTIES, {
    nextFetchPolicy: "network-only",
  });

  const [onAttach] = useMutation(CREATE_ENTITY_PROPERTIES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: QUERY_PROPERTIES },
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: parent?.id || null,
          },
        },
      },
    ],
  });

  const properties = propertiesData?.properties ?? [];

  useQuery(QUERY_ENTITY_PROPERTIES, {
    variables: {
      id,
    },
    onCompleted: ({ entity: { properties } }) => methods.reset({ properties }),
  });

  const onSubmit = (values: { properties: IProperty[] }) => {
    onAttach({
      variables: { input: { id, properties: map(values.properties, "id") } },
    })
      .then(
        ({
          data: {
            addProperties: { properties },
          },
        }) => {
          methods.reset({ properties });

          toast.success(t("entities:floorPlan.property.toast.updated"));
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <div className="row p-3">
      <div className="col-12 col-lg-6">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="properties">
                {t("entities:floorPlan.property.form.properties")}
              </label>
              <Controller
                control={methods.control}
                name="properties"
                render={({ onChange, ...props }) => (
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    // @ts-ignore
                    getOptionLabel={({ title, type }) => `(${type}) - ${title}`}
                    // @ts-ignore
                    getOptionValue={({ id }) => id}
                    options={properties}
                    className={clsx({
                      "is-invalid": !!methods.errors["properties"],
                    })}
                    {...props}
                    onChange={(value) => onChange(value || [])}
                  />
                )}
              />
              {!!methods.errors["properties"] && (
                <div className="invalid-feedback">
                  {methods.errors["properties"].message}
                </div>
              )}
            </div>
            <input
              type="submit"
              className="btn btn-primary mb-3"
              value="Save"
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PropertyRoute;
