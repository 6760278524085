import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Switch,
  NavLink,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import clsx from "clsx";
import last from "lodash/last";
import useMedia from "react-use/lib/useMedia";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { EntityType } from "../../config/const/entity";
import { QUERY_ENTITIES, QUERY_ENTITY } from "../../config/graphql/query";

import { EntityProvider } from "../../context/Entity";

import Entity from "./Entity";

const EntityRoute = React.memo(() => {
  //@ts-ignore
  const { id } = useParams();
  const { t } = useTranslation(["entities"]);

  const { pathname } = useLocation();
  const { url, path, isExact } = useRouteMatch();

  const { data: entityData } = useQuery(QUERY_ENTITY, {
    skip: !id,
    variables: {
      id,
    },
  });

  const entity: TEntity | null = entityData?.entity ?? null;

  const isSpace = entity?.type === EntityType.Space;
  const isFloor = entity?.type === EntityType.Floor;

  const { data: entitiesData } = useQuery(QUERY_ENTITIES, {
    variables: {
      filter: {
        parent: id || null,
      },
    },
  });

  const entities: Array<TEntity> = entitiesData?.entities ?? [];

  const isWide = useMedia("(min-width: 1200px)");

  const isPreview = useMemo(() => {
    const matchPathname = pathname
      .replace("/property", "")
      .replace("/floorplan", "");

    return matchPathname === url;
  }, [pathname, url]);

  const hidden = useMemo(() => {
    if (isSpace) {
      return true;
    }

    // !isWide && !isPreview;
    if (isPreview) {
      return false;
    }

    return !isWide;
  }, [isPreview, isSpace, isWide]);

  const element = document.querySelector("ol.breadcrumb");

  const collapsed = useMemo(() => {
    if (isExact) {
      return false;
    }

    const parts = pathname
      .split("/")
      .slice(2)
      .filter((k) => k.length === 24);

    return last(parts) !== id;
  }, [pathname, id, isExact]);

  return (
    <>
      {!!(element && !!entity?.id) &&
        ReactDOM.createPortal(
          <li className="breadcrumb-item">
            <NavLink to={`${url}/view`}>{entity?.title ?? entity?.id}</NavLink>
          </li>,
          element
        )}
      <EntityProvider value={{ id }}>
        {!hidden && (
          <div
            className="col-auto border-right"
            style={{
              flex: "1",
              maxWidth: 250,
            }}
          >
            <ul className="list-group list-group-flush overflow-auto position-absolute-full">
              <NavLink
                className="list-group-item list-group-item-success mr-0"
                to={`${url}/new`}
              >
                {t("entities:entitiesRouter.nav.add")}
              </NavLink>
              {entities.map(
                ({ id: entityId, title, propertiesCount, entitiesCount }) => {
                  let pathname = `${url}/${entityId}/view`;

                  // @ts-ignore
                  if (pathname.endsWith("property")) {
                    pathname += "/property";
                  } else if (pathname.endsWith("floorplan")) {
                    pathname += "/floorplan";
                  }

                  return (
                    <NavLink
                      key={entityId}
                      className="list-group-item text-decoration-none d-flex justify-content-between align-items-center mr-0"
                      to={pathname}
                      title={title}
                    >
                      <span className="text-truncate">
                        {title || `#${entityId}`}
                      </span>
                      {true && (
                        <div className="d-flex">
                          {!isFloor && (
                            <span className={"badge badge-success ml-5"}>
                              {entitiesCount}
                            </span>
                          )}
                          <span
                            className={clsx("badge badge-info ml-2", {
                              "ml-5": isFloor,
                            })}
                          >
                            {propertiesCount}
                          </span>
                        </div>
                      )}
                    </NavLink>
                  );
                }
              )}
            </ul>
          </div>
        )}

        <Switch>
          <Route exact path={`${url}/new`}>
            <EntityProvider value={{ id }}>
              <Entity />
            </EntityProvider>
          </Route>
          {!!id && (
            <Route path={`${path}/view`}>
              <Entity id={id} />
            </Route>
          )}
          {!isSpace && (
            <Route path={`${url}/:id`}>
              <EntityProvider value={{ id }}>
                <EntityRoute />
              </EntityProvider>
            </Route>
          )}
        </Switch>
      </EntityProvider>
    </>
  );
});

const EntitiesRouter = React.memo(() => {
  const { t } = useTranslation(["entities"]);

  return (
    <div className="container-fluid p-0 d-flex flex-column flex-grow-1">
      {/* <Breadcrumbs /> */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <NavLink to={"/entity"}>
              {t("entities:entitiesRouter.nav.entities")}
            </NavLink>
          </li>
        </ol>
      </nav>
      <div className="row no-gutters flex-grow-1">
        <EntityRoute />
      </div>
    </div>
  );
});

export default EntitiesRouter;
