import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "../../../components/Input";

import { QUERY_CLIENT_TWILIO_CLIENT } from "../../../config/graphql/query";
import {
  CREATE_TWILIO_CLIENT,
  UPDATE_TWILIO_CLIENT,
} from "../../../config/graphql/mutation";

type FormVariables = IClientTwilio;

const TwilioClientForm = React.memo(() => {
  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const {
    params: { id, clientId },
  } = useRouteMatch<{ id: string; clientId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(),
        description: yup.string(),
        apiKey: yup.string().required(),
        apiSecret: yup.string().required(),
        applicationId: yup.string().required(),
        accountSid: yup.string().required(),
        callerId: yup.string(),
      }),
    []
  );

  const methods = useForm<FormVariables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {},
  });

  const [onCreate] = useMutation(CREATE_TWILIO_CLIENT);
  const [onUpdate] = useMutation(UPDATE_TWILIO_CLIENT);

  const { data: twilioClientData } = useQuery<{ twilioClient: IClientTwilio }>(
    QUERY_CLIENT_TWILIO_CLIENT,
    {
      skip: !id,
      variables: { id: id },
      onCompleted: ({ twilioClient }) => {
        methods.reset(twilioClient);
      },
    }
  );

  const data = twilioClientData?.twilioClient;

  const onSubmit = (input: FormVariables) => {
    if (id) {
      return onUpdate({
        variables: {
          input: {
            ...input,
            id,
          },
        },
      })
        .then(() => {
          toast.success(t("client:client.token.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          ...input,
          client: clientId,
        },
      },
    })
      .then(
        ({
          data: {
            addTwilioClient: { id },
          },
        }) => {
          history.replace(`/client/${clientId}/twilio/${id}`);
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={"/client"}>{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/client/${clientId}`}>
              {data?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/client/${clientId}/twilio`}>
              {t("client:client.token.nav.twilio")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Input name="description" className="form-control" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="apiKey">API Key</label>
              <Input
                name="apiKey"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"SK".padEnd(32, "*")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="apiSecret">API Secret</label>
              <Input
                name="apiSecret"
                className="form-control"
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label htmlFor="applicationId">Application ID</label>
              <Input
                name="applicationId"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"AP".padEnd(32, "*")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="accountSid">Account SID</label>
              <Input
                name="accountSid"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"AC".padEnd(32, "*")}
              </small>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="callerId">Caller ID</label>
              <Input name="callerId" className="form-control" />
              <small id="title" className="form-text text-muted">
                Phone number used by Twilio Voice
              </small>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default TwilioClientForm;
