import React, { useCallback } from "react";

import clsx from "clsx";
import map from "lodash/map";

import { Link, useLocation } from "react-router-dom";

import { useEntity } from "../../../../context/Entity";

import { useFloorplan } from "./";

const EntityPopup = () => {
  const entity = useEntity();

  const { pathname } = useLocation();

  const floorplan = useFloorplan();

  const properties = entity?.properties ?? [];

  const renderProperty = useCallback(({ id, title }: TEntity) => {
    return (
      <a
        className="m-0 d-block"
        href={`/company/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    );
  }, []);

  const onBeforeEdit = useCallback(() => {
    floorplan.current?.closePopup();
  }, [floorplan]);

  return (
    <div className="p-1">
      <h4
        className={clsx("", {
          "mb-3": properties.length > 0,
        })}
      >
        {entity?.title}
      </h4>
      {map(properties, renderProperty)}
      <Link
        className="btn btn-sm btn-secondary text-white mt-2"
        role="button"
        to={{
          pathname,
          search: `?edit=${entity?.id}`,
        }}
        onClick={onBeforeEdit}
      >
        Manage entity
      </Link>
    </div>
  );
};

export default EntityPopup;
