import React, { useMemo, useContext } from "react";

import get from "lodash/get";

import { useQuery, useApolloClient } from "@apollo/client";

import {
  QUERY_AUTH,
  QUERY_CURRENT_CLIENT,
  QUERY_CLIENTS,
} from "../config/graphql/query";

type ClientValue = {
  client: null | IClient;
  clients: IClient[];
};

const ClientContext = React.createContext<ClientValue>({
  client: null,
  clients: [],
});

const { Provider } = ClientContext;

export const useCurrentClient = () => {
  const { client } = useContext(ClientContext);

  return client;
};

export const useClients = () => {
  const { clients } = useContext(ClientContext);

  return clients;
};

export const ClientProvider = React.memo(({ children }) => {
  const {
    // @ts-ignore
    data: { clientId },
  } = useQuery(QUERY_AUTH);

  const { cache } = useApolloClient();

  const { data: clientData } = useQuery(QUERY_CURRENT_CLIENT, {
    skip: !clientId,
    onError: () => {
      window.localStorage.removeItem("Client");

      cache.writeQuery({
        query: QUERY_AUTH,
        data: {
          clientId: "",
        },
      });
    },
  });

  const { data: clientsData } = useQuery(QUERY_CLIENTS, {
    // skip: clientId,
    onCompleted: ({ clients }) => {
      const initialClientId = get(clients, "[0].id");

      if (!clientId && !!initialClientId) {
        window.localStorage.setItem("Client", `${initialClientId}`);

        cache.writeQuery({
          query: QUERY_AUTH,
          data: {
            clientId: initialClientId,
          },
        });
      }
    },
  });

  const clients = useMemo(() => get(clientsData, "clients") || [], [
    clientsData,
  ]);

  const client = useMemo(() => get(clientData, "client"), [clientData]);

  return (
    <Provider value={{ client, clients }}>
      {!!client ? children : null}
    </Provider>
  );
});
