import React, { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  useRouteMatch,
  Route,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";

import dayjs from "dayjs";
import qs from "query-string";
import { useTranslation } from "react-i18next";

import useDebounce from "react-use/lib/useDebounce";

import { QUERY_ERRORS_LOG } from "../../config/graphql/query";

import {
  DELETE_ERROR_LOG,
  DELETE_MANY_ERROR_LOGS,
} from "../../config/graphql/mutation";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import ErrorLog from "./ErrorLog/index";
import Pagination from "../../components/Pagination";

const limit = 10;

const TableRow = ({ item }: { item: IErrorLog }) => {
  const { id, createdAt, errorMessage } = item;
  const [visible, setVisible] = useToggle(false);
  const { t } = useTranslation(["errorLogs", "common"]);

  const [onDelete] = useMutation(DELETE_ERROR_LOG, {
    refetchQueries: [{ query: QUERY_ERRORS_LOG }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {createdAt}
        </th>
        <td>{errorMessage}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`errorLog/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("errorLogs:errorLogs.modal.title", { count: 1 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("errorLogs:errorLogs.modal.body", { id: item.id, count: 1 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const generateOrFilter = (fields: string[], value: string) =>
  fields.map((key) => ({
    [key]: {
      REGEX: value,
      OPTIONS: "i",
    },
  }));

const ErrorsLog = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["errorLogs", "common"]);

  const [onDeleteMany] = useMutation(DELETE_MANY_ERROR_LOGS, {
    variables: {
      filter: {
        createdAt: { LTE: dayjs().subtract(6, "month").toISOString() },
      },
    },
    refetchQueries: [{ query: QUERY_ERRORS_LOG }],
  });

  const onBeforeDelete = () => {
    onDeleteMany();

    return setVisible(false);
  };

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search]
  );
  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    let nextVariables = {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      sort: {
        createdAt: "DESC",
      },
      filter: {},
    };

    if (query.search) {
      const { filter: prevFilter } = nextVariables;

      nextVariables = {
        ...nextVariables,
        filter: {
          ...prevFilter,
          OR: generateOrFilter(
            [
              "method",
              "endpoint",
              "payload",
              "rawHeaders",
              "errorMessage",
              "errorStack",
              "ip",
            ],
            query.search
          ),
        },
      };
    }

    return nextVariables;
  }, [page, query.search]);

  const { data } = useQuery<{
    errorsLog?: Array<IErrorLog>;
    errorsCount: number;
  }>(QUERY_ERRORS_LOG, {
    fetchPolicy: "network-only",
    variables,
  });

  const logs = data?.errorsLog ?? [];
  const errorsCount = data?.errorsCount ?? 0;

  const renderLog = useCallback(
    (item: IErrorLog) => <TableRow key={item.id} item={item} />,
    []
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({ page: 1, search: search }),
      });
    },
    1000,
    [search]
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("errorLogs:errorLogs.nav.errorLogs")}
          </li>
        </ol>
      </nav>
      <div className="d-flex m-2">
        <input
          className="pl-2"
          placeholder={t("common:search")}
          value={search}
          onChange={({ target: { value } }) => {
            setSearch(value);
          }}
        />
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("errorLogs:errorLogs.th.created")}</th>
              <th scope="col">{t("errorLogs:errorLogs.th.errorMessage")}</th>
              <th scope="col">{t("errorLogs:errorLogs.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{logs.map(renderLog)}</tbody>
        </table>
      </div>
      <Pagination documentsCount={errorsCount} limit={limit} />
      <hr />

      <span>{t("errorLogs:errorLogs.span", { count: 6 })}</span>
      <Button
        size="sm"
        variant="danger"
        className="mr-2 ml-3"
        onClick={() => setVisible(true)}
      >
        {t("common:delete")}
      </Button>
      <hr />
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("errorLogs:errorLogs.modal.title", { count: 6 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("errorLogs:errorLogs.modal.body", { count: 6 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

const ErrorsRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ErrorsLog />
      </Route>
      <Route path={`${path}/:id`}>
        <ErrorLog />
      </Route>
    </Switch>
  );
});

export default ErrorsRoute;
