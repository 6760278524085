import { gql } from "@apollo/client";

import {
  FRAGMENT_ENTITY,
  FRAGMENT_COMPANY,
  FRAGMENT_CLIENT,
  FRAGMENT_NAMEBOARD,
  FRAGMENT_VISIONECT,
  FRAGMENT_USER,
  FRAGMENT_ENTITY_DESK,
  FRAGMENT_VISITOR,
  FRAGMENT_MEETING,
  FRAGMENT_CLIENT_TOKEN,
  FRAGMENT_OUTLOOK_SUBSCRIPTION,
  FRAGMENT_LOG,
  FRAGMENT_ERROR,
  FRAGMENT_NAMEBOARD_SETTINGS,
  FRAGMENT_CLIENT_SENDGRID,
  FRAGMENT_CLIENT_SENDGRID_TEMPLATE,
} from "../fragment";

export const CREATE_COMPANY = gql`
  mutation addCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const UPDATE_PROPERTY_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const CREATE_SPACE = gql`
  mutation addSpace($input: AddSpaceInput!) {
    addSpace(input: $input) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_SPACE = gql`
  mutation updateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_SPACE = gql`
  mutation deleteSpace($id: ID!) {
    deleteSpace(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_FLOOR = gql`
  mutation deleteFloor($id: ID!) {
    deleteFloor(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_SECTOR = gql`
  mutation deleteSector($id: ID!) {
    deleteSector(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: ID!) {
    deleteBuilding(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const CREATE_CLIENT = gql`
  mutation addClient($input: AddClientInput!) {
    addClient(input: $input) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const ADD_CLIENT_TOKEN = gql`
  mutation addClientToken($input: AddClientTokenInput!) {
    addClientToken(input: $input) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const UPDATE_CLIENT_TOKEN = gql`
  mutation updateClientToken($input: UpdateClientTokenInput!) {
    updateClientToken(input: $input) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const DELETE_CLIENT_TOKEN = gql`
  mutation deleteClientToken($input: DeleteClientTokenInput!) {
    deleteClientToken(input: $input) {
      id
    }
  }
`;

export const CREATE_CLIENT_SENDGRID = gql`
  mutation addSendGridClient($input: AddSendGridClientInput!) {
    addSendGridClient(input: $input) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const UPDATE_CLIENT_SENDGRID = gql`
  mutation updateSendGridClient($input: UpdateSendGridClientInput!) {
    updateSendGridClient(input: $input) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const DELETE_CLIENT_SENDGRID = gql`
  mutation deleteSendGridClient($input: DeleteSendGridClientInput!) {
    deleteSendGridClient(input: $input) {
      id
    }
  }
`;

export const CREATE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation addEmailTemplate($input: AddEmailTemplateInput!) {
    addEmailTemplate(input: $input) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const UPDATE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation updateEmailTemplate($input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(input: $input) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const DELETE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation deleteEmailTemplate($input: DeleteEmailTemplateInput!) {
    deleteEmailTemplate(input: $input) {
      id
    }
  }
`;

export const CREATE_NAMEBOARD = gql`
  mutation addNameboard($input: AddNameboardInput!) {
    addNameboard(input: $input) {
      ...FragmentNameboard
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const DELETE_BOARD = gql`
  mutation deleteNameboard($id: ID!) {
    deleteNameboard(id: $id) {
      ...FragmentNameboard
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const UPDATE_NAMEBOARD = gql`
  mutation updateNameboard($input: UpdateNameboardInput!) {
    updateNameboard(input: $input) {
      ...FragmentNameboard
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const UPDATE_NAMEBOARD_SETTINGS = gql`
  mutation updateNameboard($input: UpdateNameboardInput!) {
    updateNameboard(input: $input) {
      id
      settings {
        ...FragmentNameboardSettings
      }
    }
  }
  ${FRAGMENT_NAMEBOARD_SETTINGS}
`;

export const CREATE_ENTITY_PROPERTIES = gql`
  mutation addProperties($input: AddPropertiesInput!) {
    addProperties(input: $input) {
      id
      properties {
        id
        type
        ... on Company {
          title
        }
      }
    }
  }
`;

export const CREATE_NAMEBOARD_ITEM = gql`
  mutation addNameboardItem($input: AddNameboardItemInput!) {
    addNameboardItem(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_NAMEBOARD_ITEM = gql`
  mutation updateNameboardItem($input: UpdateNameboardItemInput!) {
    updateNameboardItem(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_NAMEBOARD_ITEM = gql`
  mutation deleteNameboardItem($id: ID!) {
    deleteNameboardItem(id: $id) {
      nameboard {
        id
      }
    }
  }
`;

export const DELETE_ENTITIES = gql`
  mutation deleteEntities($ids: [ID]!) {
    deleteEntities(ids: $ids)
  }
`;

export const CREATE_NAMEBOARD_PATH = gql`
  mutation addNameboardPath($input: AddNameboardPathInput!) {
    addNameboardPath(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_NAMEBOARD_PATH = gql`
  mutation updateNameboardPath($input: UpdateNameboardPathInput!) {
    updateNameboardPath(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_NAMEBOARD_PATH = gql`
  mutation deleteNameboardPath($id: ID!) {
    deleteNameboardPath(id: $id) {
      nameboard {
        id
      }
    }
  }
`;

export const CREATE_VISIONECT = gql`
  mutation createVisionect($input: CreateVisionect!) {
    createVisionect(input: $input) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const UPDATE_VISIONECT = gql`
  mutation updateVisionect($input: UpdateVisionect!) {
    updateVisionect(input: $input) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const DELETE_VISIONECT = gql`
  mutation deleteVisionect($id: ID!) {
    deleteVisionect(id: $id) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const CREATE_VISIONECT_ITEM = gql`
  mutation createVisionectItem($input: CreateVisionectItemInput!) {
    createVisionectItem(input: $input) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_VISIONECT_ITEM = gql`
  mutation updateVisionectItem($input: UpdateVisionectItemInput!) {
    updateVisionectItem(input: $input) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_VISIONECT_ITEM = gql`
  mutation deleteVisionectItem($id: ID!) {
    deleteVisionectItem(id: $id) {
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const CREATE_USER = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const ADD_ENTITY_DESK = gql`
  mutation addEntityDesk($input: AddEntityDeskInput!) {
    addEntityDesk(input: $input) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const UPDATE_ENTITY_DESK = gql`
  mutation updateEntityDesk($input: UpdateEntityDeskInput!) {
    updateEntityDesk(input: $input) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const DELETE_ENTITY_DESK = gql`
  mutation deleteEntityDesk($id: ID!) {
    deleteEntityDesk(id: $id) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const CREATE_VISITOR = gql`
  mutation addVisitor($input: AddVisitor!) {
    addVisitor(input: $input) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const UPDATE_VISITOR = gql`
  mutation updateVisitor($input: UpdateVisitor!) {
    updateVisitor(input: $input) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const DELETE_VISITOR = gql`
  mutation deleteVisitor($id: ID!) {
    deleteVisitor(id: $id) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const CREATE_MEETING = gql`
  mutation addMeeting($input: AddMeeting!) {
    addMeeting(input: $input) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const UPDATE_MEETING = gql`
  mutation updateMeeting($input: UpdateMeeting!) {
    updateMeeting(input: $input) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const DELETE_MEETING = gql`
  mutation deleteMeeting($id: ID!) {
    deleteMeeting(id: $id) {
      id
    }
  }
`;

export const UPDATE_OUTLOOK_SUBSCRIPTION = gql`
  mutation updateOutlookSubscription($input: UpdateOutlookSubscriptionInput!) {
    updateOutlookSubscription(input: $input) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const DELETE_OUTLOOK_SUBSCRIPTION = gql`
  mutation deleteOutlookSubscription($id: ID!) {
    deleteOutlookSubscription(id: $id) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;
export const DELETE_LOG = gql`
  mutation deleteLog($id: ID!) {
    deleteLog(id: $id) {
      ...FragmentLog
    }
  }
  ${FRAGMENT_LOG}
`;

export const DELETE_MANY_LOGS = gql`
  mutation deleteManyLogs($filter: LogFilterType) {
    deleteManyLogs(filter: $filter) {
      deletedCount
    }
  }
`;

export const DELETE_ERROR_LOG = gql`
  mutation deleteErrorLog($id: ID!) {
    deleteErrorLog(id: $id) {
      ...FragmentError
    }
  }
  ${FRAGMENT_ERROR}
`;

export const DELETE_MANY_ERROR_LOGS = gql`
  mutation deleteManyErrorLogs($filter: ErrorLogFilterType) {
    deleteManyErrorLogs(filter: $filter) {
      deletedCount
    }
  }
`;

export const UPDATE_TWILIO_CLIENT = gql`
  mutation updateTwilioClient($input: UpdateTwilioClientInput!) {
    updateTwilioClient(input: $input) {
      id
    }
  }
`;

export const CREATE_TWILIO_CLIENT = gql`
  mutation addTwilioClient($input: AddTwilioClientInput!) {
    addTwilioClient(input: $input) {
      id
    }
  }
`;
