import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useMutation, useQuery } from "@apollo/client";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "../../../components/Input";

import {
  QUERY_USER,
  QUERY_USERS,
  QUERY_COMPANIES,
} from "../../../config/graphql/query";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
} from "../../../config/graphql/mutation";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const roleOptions = [
  { value: "ADMIN", label: "ADMIN" },
  { value: "CLIENT", label: "CLIENT" },
  { value: "USER", label: "USER" },
  { value: "TORO1", label: "TORO1" },
];

type UserInput = Omit<IUser, "roles"> & {
  passwordConfirm?: string;
  roles: Array<{ value: string; label: string }>;
};

const UserCreate = React.memo((props) => {
  const [show, setShow] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["user", "common"]);

  const { id } = useParams<{ id: string }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      ...(id
        ? {
            email: yup.string(),
            firstName: yup.string(),
            lastName: yup.string(),
            password: yup
              .string()
              .test(
                "empty-check",
                t("user:user.yup.password"),
                (value) => !value || value.length >= 8
              ),
            passwordConfirm: yup
              .string()
              .test(
                "passwords-match",
                t("user:user.yup.passwordConfirm"),
                function (value) {
                  return this.parent.password === value;
                }
              ),
          }
        : {
            email: yup.string().required(),
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            password: yup.string().min(8).required(),
            passwordConfirm: yup
              .string()
              .test(
                "passwords-match",
                t("user:user.yup.passwordConfirm"),
                function (value) {
                  return this.parent.password === value;
                }
              ),
          }),
      language: yup.string().oneOf(["en", "nl"]),

      roles: yup
        .array()
        .of(
          yup.object().shape({
            value: yup
              .string()
              .oneOf(["ADMIN", "CLIENT", "USER", "TORO1"])
              .required(),
            label: yup.string(),
          })
        )
        .required(),
      companies: yup.array().of(
        yup.object().shape({
          id: yup.string(),
        })
      ),
    });
  }, [id, t]);

  useQuery(QUERY_USER, {
    skip: !id,
    variables: { id },
    onCompleted: ({ user: { roles, ...user } }) => {
      methods.reset({
        ...user,
        roles: roles.map((roleCode: string) => ({
          value: roleCode,
          label: roleCode,
        })),
      });
    },
  });

  const [onUpdate] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const [onCreate] = useMutation(CREATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const [onDelete] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const { data: companiesData } = useQuery(QUERY_COMPANIES, {
    nextFetchPolicy: "network-only",
  });

  const companyOptions = useMemo(() => companiesData?.companies ?? [], [
    companiesData,
  ]);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      history.replace(`/user`);

      return onDelete({ variables: { id } })
        .then(() => {
          toast.success(t("user:user.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [onDelete, id, history, t]
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  const onSubmit = ({
    roles,
    companies,
    passwordConfirm,
    ...values
  }: UserInput) => {
    const input = {
      ...values,
      roles: roles.map(({ value }) => value),
      companies: companies && companies.map((company) => company?.id),
      ...(id && { id }),
    };

    if (id) {
      return onUpdate({ variables: { input } })
        .then(() => {
          history.replace(`/user`);
          toast.success(t("user:user.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addUser: { id },
          },
        }) => {
          toast.success(t("user:user.toast.created"));
          history.replace(`/user/${id}`);
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/user"}>{t("user:user.nav.user", { count: 2 })}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("user:user.nav.user", { count: 1 })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="firstName">
                  {t("user:user.form.firstName")}
                </label>
                <Input name="firstName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("user:user.form.lastName")}</label>
                <Input name="lastName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("user:user.form.email")}</label>
                <Input
                  name="email"
                  className="form-control"
                  autoComplete="email"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  {id
                    ? t("user:user.form.password", { count: 1 })
                    : t("user:user.form.password", { count: 2 })}
                </label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  {t("user:user.form.confirmPassword")}
                </label>
                <Input
                  name="passwordConfirm"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                />
              </div>

              <div className="form-group">
                <label htmlFor="roles">{t("user:user.form.role")}</label>
                <Controller
                  control={methods.control}
                  name="roles"
                  render={({ onChange, ...props }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={roleOptions}
                      {...props}
                      onChange={(value) => onChange(value || [])}
                    />
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="companies">
                  {t("user:user.form.companies")}
                </label>
                <Controller
                  control={methods.control}
                  name="companies"
                  render={({ onChange, ...props }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      aria-describedby="company-help"
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ id }) => id}
                      options={companyOptions}
                      onChange={(value) => onChange(value || [])}
                      {...props}
                    />
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="language">{t("user:user.form.language")}</label>
                <select
                  name="language"
                  id="language"
                  className="custom-select"
                  ref={methods.register}
                >
                  <option value="en">EN</option>
                  <option value="nl">NL</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
              {id && (
                <button
                  onClick={onBeforeRemove}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user:user.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("user:user.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default UserCreate;
