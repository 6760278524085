import React, { useCallback } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import map from "lodash/map";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { useRouteMatch, Link } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import { QUERY_NAMEBOARD_ITEMS } from "../../../../config/graphql/query";
import { DELETE_NAMEBOARD_ITEM } from "../../../../config/graphql/mutation";

const TableRow = ({ item }: { item: INameboardItem }) => {
  const { url } = useRouteMatch();

  const { id, entity, sort } = item;

  const { t } = useTranslation(["screens", "common"]);

  const propertiesTitle = map(entity.properties, "title").join(", ");

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_NAMEBOARD_ITEM, {
    refetchQueries: [{ query: QUERY_NAMEBOARD_ITEMS }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr className="">
        <th scope="row">{entity?.title}</th>
        <td>{propertiesTitle}</td>
        <td>{sort}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("screens:screen.items.items.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screen.items.items.modal.body", { item: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BoardItemsRoute = React.memo(() => {
  const {
    url,
    params: { screenId },
  }: {
    url: string;
    path: string;
    params: { screenId: string };
  } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { data } = useQuery(QUERY_NAMEBOARD_ITEMS, {
    variables: {
      filter: {
        nameboard: screenId,
      },
    },
    nextFetchPolicy: "network-only",
  });

  const items = data?.nameboardItems ?? [];

  const renderItem = useCallback(
    (item: INameboardItem) => (
      <TableRow key={`screens-${item.id}`} item={item} />
    ),
    []
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("screens:screen.items.items.button.addItem")}
          </Link>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">{t("screens:screen.items.items.th.entities")}</th>
            <th scope="col">{t("screens:screen.items.items.th.properties")}</th>
            <th scope="col">{t("screens:screen.items.items.th.sort")}</th>
            <th scope="col">{t("screens:screen.items.items.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{items.map(renderItem)}</tbody>
      </table>
    </>
  );
});

export default BoardItemsRoute;
