import React, { useCallback, useState } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useDropzone } from "react-dropzone";
import { useQuery } from "@apollo/client";

import { QUERY_AUTH } from "../../../../config/graphql/query";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useEntity } from "../../../../context/Entity";

const API_URL = process.env.REACT_APP_API_URL as string;

const [protocol, host] = API_URL.split("//");

const BASE_URL = `${protocol === "https:" ? "wss" : "ws"}://${host}`;

type TSocketUploadResponse = {
  error: boolean;
  message: string;
  file: IFile;
};

const FloorForm = () => {
  const {
    //@ts-ignore
    data: { token, clientId },
  } = useQuery(QUERY_AUTH);

  const { t } = useTranslation(["entities"]);

  const { setValue, register } = useFormContext();

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      // @todo
      if (!(Array.isArray(acceptedFiles) && acceptedFiles.length > 0)) {
        return;
      }

      const socket = new WebSocket(`${BASE_URL}/upload`, [token, clientId]);

      socket.onopen = function (e) {
        const reader = new FileReader();

        reader.onloadend = function () {
          console.log("onloadend");
        };

        reader.onload = function (e) {
          if (!e.target?.result) {
            return;
          }

          toast.info(t("entities:entity.floor.toast.info"));

          socket.send(
            JSON.stringify({
              filename: acceptedFiles[0].name,
              ext: acceptedFiles[0].name.split(".").pop(),
              file: e.target.result,
            })
          );
        };

        reader.readAsDataURL(acceptedFiles[0]);
      };

      socket.onmessage = function (e) {
        const { file: fileUploaded } = JSON.parse(
          e.data
        ) as TSocketUploadResponse;

        register("plan");
        setValue("plan", fileUploaded.id);

        toast.success(t("entities:entity.floor.toast.success"));

        socket.close();
      };

      socket.onerror = function (error) {
        toast.error(t("entities:entity.floor.toast.error"));

        socket.close();
      };
    },
    [token, clientId, register, setValue, t]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["image/*"],
    multiple: false,
  });

  const entity = useEntity() as IFloor;

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="file">Floorplan</label>
          <div
            className={clsx("bg-light border rounded py-4  position-relative", {
              "border-dark": !isDragActive,
              "border-primary": isDragActive,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} name="file" />
            <div className="d-flex flex-column align-items-center">
              <i className="fa fa-upload text-muted h2" />
              {t("entities:entity.floor.form.i")}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 row">
        <div className="col-6">
          {entity?.plan && (
            <a
              href={entity.plan.absolutePath}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="background-image bg-16-9 border border-dark"
                style={{ backgroundImage: `url("${entity.plan.absolutePath}")` }}
              />
            </a>
          )}
        </div>
        <div className="col-6">
          {/* <button className="btn btn-danger">Remove</button> */}
        </div>
      </div>

      {false && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: "75%" }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FloorForm;
