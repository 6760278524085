import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import Input from "../../../components/Input";

import { QUERY_CLIENT } from "../../../config/graphql/query";
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "../../../config/graphql/mutation";

const ClientInformation = React.memo(() => {
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);
  const history = useHistory();

  const {
    params: { clientId },
  } = useRouteMatch<{ clientId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("client:client.information.yup.name")),
        email: yup.string().required(t("client:client.information.yup.email")),
        domain: yup
          .string()
          .url()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            t("client:client.information.yup.wrongURL")
          )
          .required(t("client:client.information.yup.domain")),
      }),
    [t]
  );

  const [onUpdate] = useMutation(UPDATE_CLIENT);
  const [onCreate] = useMutation(CREATE_CLIENT);
  const [onDelete] = useMutation(DELETE_CLIENT);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data } = useQuery(QUERY_CLIENT, {
    skip: !clientId,
    variables: { id: clientId },
    onCompleted: ({ client }) => methods.reset(client),
  });

  const onBeforeDelete = useCallback(() => {
    history.replace(`/client`);

    return onDelete({ variables: { id: clientId } })
      .then(() => {
        toast.success(t("client:client.information.toast.deleted"));
      })
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  }, [history, onDelete, clientId, t]);

  const onSubmit = (values: IClient) => {
    const input = {
      ...(clientId ? { id: clientId } : {}),
      ...values,
    };

    if (clientId) {
      return onUpdate({ variables: { input } })
        .then(() => {
          toast.success(t("client:client.information.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addClient: { id: clientId },
          },
        }) => {
          toast.success(t("client:client.information.toast.created"));

          history.replace(`/client/${clientId}`);
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.information.form.name")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                {t("client:client.information.form.email")}
              </label>
              <Input name="email" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="domain">
                {t("client:client.information.form.domain")}
              </label>
              <Input name="domain" className="form-control" />
              <sub>
                <i>{t("client:client.information.form.domain")}</i>
              </sub>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {clientId && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setVisible(true);
                }}
                className="btn btn-danger ml-3"
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.information.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.information.modal.title", {
            title: data?.client?.title,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ClientInformation;
