import React, { useCallback, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import qs from "query-string";
import dayjs from "dayjs";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";
import { Table, Button, Modal } from "react-bootstrap";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";

import { QUERY_MEETINGS } from "../../config/graphql/query";
import { DELETE_MEETING } from "../../config/graphql/mutation";
import MeetingCreate from "./Meeting/Meeting";
import Pagination from "../../components/Pagination";

const limit = 10;

const TableRow = ({ item, index }: { item: IMeeting; index: number }) => {
  const { id, dateStart, building, company, host } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["meetings", "common"]);

  const [onDelete] = useMutation(DELETE_MEETING, {
    refetchQueries: [{ query: QUERY_MEETINGS }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{dayjs(dateStart).local().format("DD-MM-YYYY / HH:mm")}</td>
        <td>{building.title}</td>
        <td>{company.title}</td>
        <td>{host.name}</td>
        <td className="text-nowrap">
          <Button
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={() => setVisible(true)}
          >
            {t("common:delete")}
          </Button>
          <Link to={`meetings/${id}`}>
            <Button size="sm" variant="primary">
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("meetings:meetings.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("meetings:meetings.modal.body", { id: id })}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Meetings = React.memo(() => {
  const match = useRouteMatch();
  const history = useHistory();

  const { t } = useTranslation(["meetings", "common"]);

  const query = useMemo(
    () =>
      qs.parse(history.location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [history.location.search]
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const { data } = useQuery<{
    meetings?: Array<IMeeting>;
    meetingsCount: number;
  }>(QUERY_MEETINGS, {
    fetchPolicy: "network-only",
    variables: {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      sort: {
        dateStart: "DESC",
      },
      filter: {},
    },
  });

  const meetingsArr = data?.meetings ?? [];
  const meetingsCount = data?.meetingsCount ?? 0;

  const renderMeeting = useCallback(
    (item: IMeeting, index) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    []
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("meetings:meetings.nav.meetings")}
          </li>
        </ol>
      </nav>
      <div className="d-flex justify-content-end align-items-center mb-4">
        <div>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary"
          >
            {t("meetings:meetings.add")}
          </Link>
        </div>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("meetings:meetings.th.start")}</th>
              <th scope="col">{t("meetings:meetings.th.location")}</th>
              <th scope="col">{t("meetings:meetings.th.company")}</th>
              <th scope="col">{t("meetings:meetings.th.host")}</th>
              <th scope="col">{t("meetings:meetings.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{meetingsArr.map(renderMeeting)}</tbody>
        </Table>
        <Pagination documentsCount={meetingsCount} limit={limit} />
      </>
    </div>
  );
});

const MeetingRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Meetings />
      </Route>
      <Route exact path={`${path}/new`}>
        <MeetingCreate />
      </Route>
      <Route path={`${path}/:id`}>
        <MeetingCreate />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default MeetingRoute;
