import React, { useMemo, useCallback } from "react";

import * as yup from "yup";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import get from "lodash/get";

import { useParams } from "react-router-dom";

import { UPDATE_NAMEBOARD_SETTINGS } from "../../../../config/graphql/mutation";
import { QUERY_NAMEBOARD_SETTINGS } from "../../../../config/graphql/query";

import Input from "../../../../components/Input";
import CodeEditor from "../../../../components/CodeEditor";

const SettingsRoute = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        layout: yup.string().oneOf(["grid", "list"]),
        plugins: yup.object().shape({
          feed: yup.object().shape({
            url: yup.string().url(),
          }),
          twitter: yup.object().shape({
            username: yup.string(),
          }),
          externalVisitors: yup.object().shape({
            id: yup.string(),
            url: yup.string().url(),
          }),
        }),
        hardware: yup.string(),
        support: yup.string(),
      }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [onUpdate, { loading }] = useMutation(UPDATE_NAMEBOARD_SETTINGS);

  useQuery(QUERY_NAMEBOARD_SETTINGS, {
    variables: {
      id: screenId,
    },
    onCompleted: ({ nameboard: { settings } }) => {
      methods.reset({
        ...settings,
        hardware: JSON.stringify(settings?.hardware || {}, null, "\t"),
        support: JSON.stringify(settings?.support || {}, null, "\t"),
      });
    },
  });

  const onSubmit = useCallback((variables: INameboardSettings) => {
    const { layout, plugins } = variables;

    let settings: any = {
      layout,
      plugins: {},
    };

    if (variables.hardware) {
      settings = {
        ...settings,
        hardware: JSON.parse(variables.hardware),
      };
    }

    if (variables.support) {
      settings = {
        ...settings,
        support: JSON.parse(variables.support),
      };
    }

    if (plugins?.feed?.url) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          feed: plugins.feed,
        },
      };
    }

    if (plugins?.twitter?.username) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          twitter: plugins.twitter,
        },
      };
    }

    if (plugins?.externalVisitors?.id) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          externalVisitors: plugins.externalVisitors,
        },
      };
    }

    onUpdate({
      variables: {
        input: {
          id: screenId,
          settings,
        },
      },
    })
      .then(() => {
        toast.success("Success");
      })
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="layout">
                {t("screenSettings:select.label.layout")}
              </label>
              <select
                ref={methods.register}
                name="layout"
                className="custom-select"
              >
                <option value="" hidden>
                  {t("screenSettings:select.option.default")}
                </option>
                <option value="list">
                  {t("screenSettings:select.option.list")}
                </option>
                <option value="grid">
                  {t("screenSettings:select.option.grid")}
                </option>
              </select>
              {!!get(methods, "errors.layout") && (
                <div className="invalid-feedback">
                  {get(methods, "errors.layout").message}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <h6>Plugin external visitors</h6>
            <hr />
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.externalVisitors.id")}
              </label>
              <Input
                name="plugins.externalVisitors.id"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.externalVisitors.url")}
              </label>
              <Input
                name="plugins.externalVisitors.url"
                className="form-control"
              />
            </div>
            <h6 className="mt-4">Plugin twitter</h6>
            <hr />
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.twitter.username")}
              </label>
              <Input name="plugins.twitter.username" className="form-control" />
            </div>
            <h6 className="mt-4">Plugin feed</h6>
            <hr />
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.feed.url")}
              </label>
              <Input name="plugins.feed.url" className="form-control" />
            </div>
          </div>
          <div className="col-12 mb-2">
            <Controller
              name="hardware"
              control={methods.control}
              render={(props) => <CodeEditor {...props} />}
            />
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default SettingsRoute;
