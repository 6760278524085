import React, { useCallback } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation } from "@apollo/client";

import { QUERY_CLIENTS } from "../../config/graphql/query";
import { DELETE_CLIENT } from "../../config/graphql/mutation";
import { Route, Switch, useRouteMatch, Redirect, Link } from "react-router-dom";

import ClientPage from "./Client";
import ClientTokenForm from "./Client/ClientToken";
import TwilioClient from "./Client/TwilioClient";
import ClientSendGridList from "./Client/ClientSendGridList";
import ClientSendGrid from "./Client/ClientSendGrid";
import ClientIntegrationForm from "./Client/ClientIntegrationForm";
import ClientSendGridTemplate from "./Client/ClientSendGridTemplate";

const TableRow = ({ item, index }: { item: IClient; index: number }) => {
  const { id: clientId, title } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);

  const [onDelete] = useMutation(DELETE_CLIENT, {
    variables: {
      id: clientId,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: QUERY_CLIENTS, variables: { sort: { title: "ASC" } } },
    ],
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <th scope="row">{title}</th>

        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`/client/${clientId}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("client:clients.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:clients.modal.body", { title: title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Clients = React.memo(() => {
  const match = useRouteMatch();

  const { data } = useQuery(QUERY_CLIENTS, {
    variables: { sort: { title: "ASC" } },
  });

  const { t } = useTranslation(["client", "common"]);

  const clients: Array<IClient> = data?.clients ?? [];

  const renderClient = useCallback(
    (item: IClient, index) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    []
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("client:clients.nav.clients")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary"
        >
          {t("client:clients.button.add")}
        </Link>
      </div>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("client:clients.th.title")}</th>
            <th scope="col">{t("client:clients.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{clients.map(renderClient)}</tbody>
      </table>
    </div>
  );
});

const ClientRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:clientId/sendgrid-templates/new`}>
        <ClientSendGridTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid/new`}>
        <ClientSendGrid />
      </Route>
      <Route exact path={`${path}/:clientId/token/new`}>
        <ClientTokenForm />
      </Route>
      <Route exact path={`${path}/:clientId/twilio/new`}>
        <TwilioClient />
      </Route>
      <Route exact path={path}>
        <Clients />
      </Route>
      <Route path={`${path}/new`}>
        <ClientPage />
      </Route>
	  <Route exact path={`${path}/:clientId/sendgrid-templates/:id`}>
        <ClientSendGridTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/twilio/:id`}>
        <TwilioClient />
      </Route>
      <Route exact path={`${path}/:clientId/integration/:subscriptionId`}>
        <ClientIntegrationForm />
      </Route>
      <Route exact path={`${path}/:clientId/token/:id`}>
        <ClientTokenForm />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid/:id`}>
        <ClientSendGrid />
      </Route>
	  <Route path={`${path}/:clientId`}>
        <ClientPage />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default ClientRoute;
