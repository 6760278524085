import React, { useState, useCallback, useMemo, useRef } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
  ArrayField,
} from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import get from "lodash/get";
import map from "lodash/map";
import omit from "lodash/omit";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import TextArea from "../../../components/Textarea";
import Input from "../../../components/Input";
import {
  QUERY_MEETING,
  QUERY_BUILDINGS,
  QUERY_CHILDREN_PROPERTIES,
} from "../../../config/graphql/query";
import {
  CREATE_MEETING,
  UPDATE_MEETING,
  DELETE_MEETING,
} from "../../../config/graphql/mutation";
const Meeting = React.memo((props) => {
  const [show, setShow] = useToggle(false);
  const [showAttendee, setShowAttendee] = useState<number>();

  const { t } = useTranslation(["meetings", "common"]);

  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const dateStartRef = useRef<DatePicker>();

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup.string(),
      description: yup.string(),
      emailLanguage: yup.string().oneOf(["", "en", "de", "fr", "it"]),
      dateStart: yup
        .date()
        .test("allow", t("meetings:meeting.yup.start.test"), function (value) {
          if (value instanceof Date) {
            return dayjs(value).isAfter(dayjs(new Date(Date.now())));
          }
          return false;
        })
        .required(t("meetings:meeting.yup.start.message")),
      dateEnd: yup
        .mixed()
        .test("allow", t("meetings:meeting.yup.end.test"), function (value) {
          if (value instanceof Date) {
            return dayjs.utc(value).isAfter(dayjs(this.parent.dateStart));
          }
          return true;
        }),
      building: yup.object().shape({
        id: yup.string().required(t("meetings:meeting.yup.building")),
      }),
      company: yup
        .object()
        .shape({
          id: yup.string(),
        })
        .required(t("meetings:meeting.yup.company")),
      host: yup.object().shape({
        name: yup.string().required(t("meetings:meeting.yup.host.name")),
        email: yup
          .string()
          .email(t("meetings:meeting.yup.host.email.email"))
          .required(t("meetings:meeting.yup.host.email.message")),
      }),
      attendees: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          email: yup
            .string()
            .email(t("meetings:meeting.yup.attendees.email.email"))
            .required(t("meetings:meeting.yup.attendees.email.message")),
        })
      ),
    });
  }, [t]);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      dateStart: new Date().setMinutes(
        Math.ceil(new Date().getMinutes() / 30) * 30
      ),
      dateEnd: undefined,
      attendees: [
        {
          name: "",
          email: "",
        },
      ],
    },
  });

  const attendees = useFieldArray<IVisitorLog>({
    control: methods.control,
    name: "attendees",
  });

  useQuery(QUERY_MEETING, {
    skip: !id,
    variables: { id },
    onCompleted: ({
      meeting: { dateStart, dateEnd, attendees, ...meeting },
    }) => {
      methods.reset({
        ...meeting,
        dateStart: new Date(dayjs(dateStart).local().format()),
        dateEnd: dateEnd
          ? new Date(dayjs(dateEnd).local().format())
          : undefined,
        attendees,
      });
    },
  });

  const selectedBuilding = methods.watch("building");

  const { data: buildingsData } = useQuery(QUERY_BUILDINGS, {
    nextFetchPolicy: "network-only",
  });

  const { data: propertiesData } = useQuery(QUERY_CHILDREN_PROPERTIES, {
    skip: !selectedBuilding,
    variables: {
      //@ts-ignore
      id: selectedBuilding?.id,
    },
    nextFetchPolicy: "network-only",
  });

  const buildingOptions = useMemo(() => buildingsData?.buildings ?? [], [
    buildingsData,
  ]);

  const companyOptions = useMemo(
    () => propertiesData?.entityChildrenProperties ?? [],
    [propertiesData]
  );

  const [onUpdate] = useMutation(UPDATE_MEETING);
  const [onCreate] = useMutation(CREATE_MEETING);
  const [onDelete] = useMutation(DELETE_MEETING);

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      history.replace(`/meetings`);

      return onDelete({ variables: { id } })
        .then(() => {
          toast.success(t("meetings:meeting.toast.deleted"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    },
    [onDelete, id, history, t]
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow]
  );

  const onSubmit = (variables: IMeeting) => {
    const {
      building,
      dateStart,
      dateEnd,
      company,
      host,
      attendees,
      ...values
    } = variables;

    let input = {
      ...values,
      dateStart: dayjs(dateStart).utc().format(),
      dateEnd: dateEnd ? dayjs(dateEnd).utc().format() : "",
      building: building.id,
      company: company.id,
      host: omit(host, ["qrPass"]),
      attendees: map(attendees, (attendee) =>
        omit(attendee, ["qrPass", "badge"])
      ),
      ...(id && { id }),
    };

    if (input?.dateEnd === "") {
      //@ts-ignore
      input = omit(input, "dateEnd");
    }

    if (id) {
      return onUpdate({ variables: { input } })
        .then(() => {
          history.replace(`/meetings/${id}`);
          toast.success(t("meetings:meeting.toast.updated"));
        })
        .catch((error) => {
          toast.error(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addMeeting: { id },
          },
        }) => {
          toast.success(t("meetings:meeting.toast.created"));
          history.replace(`/meetings/${id}`);
        }
      )
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );
      });
  };

  const renderAttendee = useCallback(
    (
      field: Partial<ArrayField<IVisitorLog, "id">>,
      index: number,
      items: Partial<ArrayField<IVisitorLog, "id">>[]
    ) => {
      return (
        <div key={field.id}>
          <div className="form-group">
            <label htmlFor={`attendees[${index}].name`}>
              {t("meetings:meeting.renderAttendee.name")}
            </label>
            <Input
              ref={methods.register()}
              name={`attendees[${index}].name`}
              className="form-control"
              defaultValue={field.name}
            />
          </div>
          <div className="form-group">
            <label htmlFor={`attendees[${index}].email`}>
              {t("meetings:meeting.renderAttendee.email")}
            </label>
            <Input
              ref={methods.register()}
              name={`attendees[${index}].email`}
              className="form-control"
              defaultValue={field.email}
            />
          </div>
          {!!id && (
            <>
              <div className="form-group">
                <label htmlFor={`attendees[${index}].badge`}>
                  {t("meetings:meeting.form.visitor.badge")}
                </label>
                <Input
                  disabled
                  readOnly
                  name={`attendees[${index}].badge`}
                  className="form-control"
                  defaultValue={field?.badge}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`attendees[${index}].qrPass.id`}>
                  {t("meetings:meeting.form.visitor.qrPass.id")}
                </label>
                <Input
                  disabled
                  readOnly
                  name={`attendees[${index}].qrPass.id`}
                  className="form-control"
                  defaultValue={field?.qrPass?.id}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`attendees[${index}].qrPass.fallbackCode`}>
                  {t("meetings:meeting.form.visitor.qrPass.fallbackCode")}
                </label>
                <Input
                  disabled
                  readOnly
                  name={`attendees[${index}].qrPass.fallbackCode`}
                  className="form-control"
                  defaultValue={field?.qrPass?.fallbackCode}
                />
              </div>
            </>
          )}
          {items.length > 1 && (
            <>
              <button
                onClick={() => {
                  setShowAttendee(index);
                }}
                className="btn btn-danger mb-4 ml-2"
              >
                {t("common:remove")}
              </button>
            </>
          )}
          <hr />
        </div>
      );
    },
    [id, methods, t]
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to={"/meetings"}>
              {t("meetings:meeting.nav.meeting", { count: 2 })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("meetings:meeting.nav.meeting", { count: 1 })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row mt-2" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 col-lg-4">
            <div>
              <label htmlFor="dateStart">
                {t("meetings:meeting.form.dateStart")}
              </label>
              <div>
                <Controller
                  control={methods.control}
                  name="dateStart"
                  onFocus={() => dateStartRef.current?.setFocus()}
                  className="w-100"
                  render={({ onChange, value, ...props }) => (
                    <DatePicker
                      className={clsx("form-control w-100", {
                        "is-invalid": !!methods.errors?.dateStart?.message,
                      })}
                      // @ts-ignore
                      ref={dateStartRef}
                      showTimeSelect
                      selected={value}
                      onChange={onChange}
                      dateFormat="MM/dd/yyyy HH:mm"
                    />
                  )}
                />
              </div>
              <div className="invalid-feedback">
                {methods.errors?.dateStart?.message ?? "Invalid"}
              </div>
            </div>
            <div>
              <label htmlFor="dateEnd">
                {t("meetings:meeting.form.dateEnd")}
              </label>
              <div>
                <Controller
                  control={methods.control}
                  name="dateEnd"
                  className="w-auto"
                  render={({ onChange, value, ...props }) => (
                    <DatePicker
                      className={clsx("form-control mb-3", {
                        "is-invalid": !!methods.errors?.dateEnd?.message,
                      })}
                      showTimeSelect
                      selected={value}
                      onChange={onChange}
                      dateFormat="MM/dd/yyyy HH:mm"
                    />
                  )}
                />
              </div>
              <div className="invalid-feedback">
                {methods.errors?.dateEnd?.message ?? "Invalid"}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="building">
                {t("meetings:meeting.form.building")}
              </label>
              <Controller
                control={methods.control}
                name="building"
                render={({ onChange, ...props }) => (
                  <Select
                    aria-describedby="building-help"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={buildingOptions}
                    onChange={(value) => onChange(value || [])}
                    {...props}
                  />
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">
                {t("meetings:meeting.form.company.name")}
              </label>
              <Controller
                control={methods.control}
                name="company"
                render={({ onChange, ...props }) => (
                  <Select
                    aria-describedby="companyHelp"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={companyOptions}
                    onChange={(value) => onChange(value || [])}
                    isDisabled={!selectedBuilding}
                    {...props}
                  />
                )}
              />
              {!selectedBuilding && (
                <small id="companyHelp" className="form-text text-muted">
                  {t("meetings:meeting.form.company.companyHelp")}
                </small>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="title">{t("meetings:meeting.form.title")}</label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                {t("meetings:meeting.form.description")}
              </label>
              <TextArea name="description" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="emailLanguage">
                {t("meetings:meeting.form.emailLanguage.label")}
              </label>
              <select
                name="emailLanguage"
                ref={methods.register}
                className="custom-select"
              >
                <option value="" hidden>
                  {t("meetings:meeting.form.emailLanguage.option")}
                </option>
                <option value="en">
                  {t("meetings:meeting.form.emailLanguage.en")}
                </option>
                <option value="de">
                  {t("meetings:meeting.form.emailLanguage.de")}
                </option>
                <option value="fr">
                  {t("meetings:meeting.form.emailLanguage.fr")}
                </option>
                <option value="it">
                  {t("meetings:meeting.form.emailLanguage.it")}
                </option>
              </select>
              <small id="emailHelp" className="form-text text-muted">
                {t("meetings:meeting.form.emailLanguage.small")}
              </small>
              {!!get(methods.errors, "emailLanguage") && (
                <div className="invalid-feedback">
                  {get(methods.errors, `emailLanguage.message`)}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <h3>{t("meetings:meeting.form.host.host")}</h3>
            <div className="form-group">
              <label htmlFor="host.name">
                {t("meetings:meeting.form.host.name")}
              </label>
              <Input name="host.name" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="host.email">
                {t("meetings:meeting.form.host.email")}
              </label>
              <Input name="host.email" className="form-control" />
            </div>
            {!!id && (
              <>
                <div className="form-group">
                  <label htmlFor="host.qrPass.fallbackCode">
                    {t("meetings:meeting.form.host.qrPass.id")}
                  </label>
                  <Input
                    disabled
                    readOnly
                    name="host.qrPass.id"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="host.qrPass.fallbackCode">
                    {t("meetings:meeting.form.host.qrPass.fallbackCode")}
                  </label>
                  <Input
                    disabled
                    readOnly
                    name="host.qrPass.fallbackCode"
                    className="form-control"
                  />
                </div>
              </>
            )}
          </div>
          <div className="col-12 col-lg-4">
            <h3>{t("meetings:meeting.form.visitors")}</h3>
            {attendees.fields.map(renderAttendee)}
            <button
              className="btn btn-secondary mb-4"
              onClick={() => {
                attendees.append({ name: "", email: "" });
              }}
            >
              {t("meetings:meeting.form.addMore")}
            </button>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {id && (
              <button onClick={onBeforeRemove} className="btn btn-danger ml-3">
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("meetings:meeting.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("meetings:meeting.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={typeof showAttendee === "number"}
        onHide={() => setShowAttendee(undefined)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("meetings:meeting.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("meetings:meeting.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAttendee(undefined)}
          >
            {t("common:cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowAttendee(undefined);
              attendees.remove(showAttendee);
            }}
          >
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Meeting;
